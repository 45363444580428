/* global i18n */
import React from "react";
import { Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import addressjson from "countrycitystatejson";
import queryString from "query-string";

import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";

import Select from "react-virtualized-select";

import { registerUser } from "../../inc/actions/authActions";
import { setInfo } from "../../inc/actions/infoActions";
// import LogoIcon from "../../assets/Logo.png";
import { checkMinLength, checkEmail } from "../../inc/Validation";

class Register extends React.Component {
  constructor(props) {
    super(props);
    let returnto = queryString.parse(props.location.search).returnto;
    returnto = returnto ? returnto : "/";
    let countries = addressjson.getCountries();
    let countryOptions = [];
    for (let index = 0; index < countries.length; index++) {
      const element = countries[index];
      countryOptions.push({
        label: element.name,
        value: element.shortName,
      });
    }
    countryOptions.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    this.state = {
      email: "",
      password: "",
      fname: "",
      lname: "",
      address1: "",
      address2: "",
      country: "",
      state: "",
      city: "",
      postalcode: "",
      phone: "",
      countryOptions: countryOptions,
      isLoggedIn: false,
      returnto,
    };
    this.props.setInfo({
      for: "currentPage",
      data: "register",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.auth.isAuthenticated) {
      nextProps.history.push("/");
    }
    if (nextProps !== prevProps) {
      return {
        ...prevProps,
      };
    }
  }

  changeHandler = (e, iselect) => {
    if (!iselect) {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      if (e.value) {
        this.setState({ [e.name]: e.value });
      } else {
        this.setState({ [e.name]: "" });
      }
    }
  };

  validate = () => {
    var errorMsg = "";
    const {
      email,
      password,
      fname,
      lname,
      address1,
      country,
      state,
      city,
      postalcode,
      phone,
    } = this.state;
    if (!fname || !checkMinLength(fname, 3)) {
      errorMsg = i18n("lengthfname");
      toast.error(errorMsg);
      return false;
    }
    if (!lname || !checkMinLength(lname, 3)) {
      errorMsg = i18n("lengthlname");
      toast.error(errorMsg);
      return false;
    }
    if (!email || !checkEmail(email)) {
      errorMsg = i18n("invalidEmail");
      toast.error(errorMsg);
      return false;
    }
    if (!password || !checkMinLength(password, 6)) {
      errorMsg = i18n("passwordatleast");
      toast.error(errorMsg);
      return false;
    }
    if (!address1 || !checkMinLength(address1, 2)) {
      errorMsg = i18n("lengthaddress");
      toast.error(errorMsg);
      return false;
    }
    if (!country) {
      errorMsg = i18n("requiredcountry");
      toast.error(errorMsg);
      return false;
    }
    const statelessCountries = {
      "FR": true
    };
    if (!state && !statelessCountries[country]) {
      errorMsg = i18n("requiredstate");
      toast.error(errorMsg);
      return false;
    }
    if (!city) {
      errorMsg = i18n("requiredcity");
      toast.error(errorMsg);
      return false;
    }
    if (!postalcode) {
      errorMsg = i18n("requiredpostalcode");
      toast.error(errorMsg);
      return false;
    }
    if (!phone) {
      errorMsg = i18n("requiredphonenumber");
      toast.error(errorMsg);
      return false;
    }
    return true;
  };
  submitHandler = (e) => {
    e.preventDefault();
    let {
      email,
      password,
      fname,
      lname,
      address1,
      address2,
      country,
      state,
      city,
      postalcode,
      phone,
      returnto,
    } = this.state;
    if (this.validate()) {
      console.log("form validated");

      country = addressjson.getCountryInfoByShort(country).name;

      const newUser = {
        email,
        password,
        fname,
        lname,
        address1,
        address2,
        country,
        state,
        city,
        postalcode,
        phone,
      };

      this.props.registerUser(newUser, this.props.history, returnto);
    } else {
      return false;
    }
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n("registerID")}</title>
        </Helmet>
        <Container className="fullscreenCenter vh70">
          <div className="registerWrap">
            <div className="brandIcon">
              <p className="label">{i18n("registerat")} Equireel</p>
            </div>
            <form action="post" onSubmit={this.submitHandler}>
              <input
                onChange={this.changeHandler}
                name="fname"
                className="form-control fname"
                type="text"
                placeholder={i18n("fname")}
                value={this.state.fname}
                style={{ width: "50%", display: "inline-block" }}
              />
              <input
                onChange={this.changeHandler}
                name="lname"
                className="form-control lname"
                type="text"
                placeholder={i18n("lname")}
                value={this.state.lname}
                style={{
                  width: "50%",
                  display: "inline-block",
                  borderLeft: "0",
                  borderTop: "2px solid rgba(217, 217, 217, 0.2)",
                }}
              />
              <input
                onChange={this.changeHandler}
                name="address1"
                className="form-control address1"
                type="text"
                placeholder={i18n("address1")}
                value={this.state.address1}
              />
              <input
                onChange={this.changeHandler}
                name="address2"
                className="form-control address2"
                type="text"
                placeholder={i18n("address2")}
                value={this.state.address2}
              />
              <Select
                options={
                  this.state.countryOptions ? this.state.countryOptions : null
                }
                onChange={(country) =>
                  this.changeHandler(
                    { name: "country", value: country ? country.value : null },
                    true
                  )
                }
                value={this.state.country}
                placeholder={i18n("selectcountry")}
                className="authV"
              />
              <input
                onChange={this.changeHandler}
                name="state"
                className="form-control state"
                type="text"
                placeholder={i18n("stateprovince")}
                value={this.state.state}
              />
              <input
                onChange={this.changeHandler}
                name="city"
                className="form-control city"
                type="text"
                placeholder={i18n("city")}
                value={this.state.city}
                style={{ width: "50%", display: "inline-block" }}
              />
              <input
                onChange={this.changeHandler}
                name="postalcode"
                className="form-control postalcode"
                type="text"
                placeholder={i18n("postalcode")}
                value={this.state.postalcode}
                style={{
                  width: "50%",
                  display: "inline-block",
                  borderLeft: "0",
                }}
              />
              <input
                onChange={this.changeHandler}
                name="phone"
                className="form-control phone"
                type="text"
                placeholder={i18n("phonenumber")}
                value={this.state.phone}
              />
              <input
                onChange={this.changeHandler}
                name="email"
                className="form-control email"
                type="text"
                placeholder={i18n("email")}
                value={this.state.email}
              />
              <input
                onChange={this.changeHandler}
                name="password"
                className="form-control password"
                type="password"
                placeholder={i18n("password")}
                value={this.state.password}
              />
              <input
                className="button bg-accent"
                type="submit"
                value={i18n("submit")}
              />
            </form>
            <p>
              <Link
                to={`/login${
                  this.state.returnto ? "?returnto=" + this.state.returnto : ""
                }`}
              >
                {i18n("alreadyHaveId")}
              </Link>
            </p>
          </div>
          <div className="spacer"></div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
});

Register.propTypes = {
  setInfo: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { registerUser, setInfo })(
  withRouter(Register)
);
