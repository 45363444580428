import { SET_INFO } from "../types";

export const setInfo = info => {
  return {
    type: SET_INFO,
    payload: {
      for: info.for,
      data: info.data
    }
  };
};
