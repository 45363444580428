/* global i18n */
import React from "react";
import { Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setInfo } from "../../inc/actions/infoActions";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { toast } from "react-toastify";

import { checkEmail } from "../../inc/Validation";
import { createResetRequest } from "../../inc/actions/authActions";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
    this.props.setInfo({
      for: "currentPage",
      data: "forgotpassword"
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.auth.isAuthenticated) {
      nextProps.history.push("/");
    }
    if (nextProps !== prevProps) {
      return {
        ...prevProps
      };
    }
  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validate = () => {
    var errorMsg = "";
    const { email } = this.state;
    if (!email || !checkEmail(email)) {
      errorMsg = i18n("invalidEmail");
      toast.error(errorMsg);
      return false;
    }
    return true;
  };
  submitHandler = e => {
    e.preventDefault();
    let returnto = queryString.parse(this.props.location.search).returnto;
    returnto = returnto ? returnto : '/';
    const { email } = this.state;
    if (this.validate()) {
      this.props.createResetRequest(email, this.props.history, returnto);
    } else {
      return false;
    }
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n('resetPassword') + ' - Equireel'}</title>
        </Helmet>
        <Container className="fullscreenCenter vh70">
          <div className="forgetPassWrap">
            <div className="brandIcon">
              <p className="label" style={{ marginBottom: '20px' }}>{i18n('resetPassword')}</p>
            </div>
            <form action="post" onSubmit={this.submitHandler}>
              <input
                onChange={this.changeHandler}
                name="email"
                className="form-control email"
                type="text"
                placeholder={i18n('email')}
                value={this.state.email}
              />
              <input className="button bg-accent" type="submit" value={i18n('submit')} />
            </form>
            <p>
              <Link to="/login">{i18n('loginToEquireel')}</Link>
            </p>
            <p>
              <Link to="/register">{i18n('dontHaveId')}</Link>
            </p>
          </div>
          <div className="spacer"></div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  info: state.info
});

ForgotPassword.propTypes = {
  createResetRequest: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { createResetRequest, setInfo })(
  withRouter(ForgotPassword)
);
