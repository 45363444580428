/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import "moment-timezone";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import S3 from "react-aws-s3";

import { setInfo } from "../../inc/actions/infoActions";
import {
  getCoachLessons,
  changeLessonStatus,
  addAttachmentFile,
  removeAttachmentFile,
} from "../../inc/actions/bookingActions";

class CoachLesson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is404: false,
      lesson: {},
      got: false,
      inputToggled: false,
      uploadToggled: false,
      isUploading: false,
    };
    this.props.setInfo({
      for: "currentPage",
      data: "account",
    });
    this.props.getCoachLessons();
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.auth.isAuthenticated) {
        if (nextProps.id) {
          if (nextProps.coach) {
            if (nextProps.coach.initialized.indexOf("coachLessons") !== -1) {
              if (nextProps.coach.coachLessons) {
                update.coachLessons = nextProps.coach.coachLessons;
                let found = false;
                for (
                  let index = 0;
                  index < update.coachLessons.length;
                  index++
                ) {
                  let userLesson = update.coachLessons[index];
                  if (userLesson._id === nextProps.id) {
                    update.id = nextProps.id;
                    update.is404 = false;
                    found = true;
                    userLesson.coach = nextProps.auth.user;
                    update.lesson = userLesson;
                  }
                }
                update.got = true;
                if (!found && update.got) {
                  console.log("redirecting to 404", 1);
                  nextProps.history.push("/404");
                }
              } else {
                console.log("redirecting to 404", 2);
                nextProps.history.push("/404");
              }
            }
          }
        } else {
          console.log("redirecting to 404", 4);
          nextProps.history.push("/404");
        }
        if (nextProps.auth) {
          update.auth = nextProps.auth;
        }
      } else {
        nextProps.history.push(`/login/?returnto=/lesson/${nextProps.id}`);
      }
      return update;
    } else {
      return null;
    }
  }
  changeStatus = (val) => {
    this.props.changeLessonStatus(this.state.lesson._id, val);
    this.props.getCoachLessons();
    this.setState({
      ...this.state,
      inputToggled: false,
    });
  };
  showChange = () => {
    this.setState({
      ...this.state,
      inputToggled: true,
    });
  };
  showLink = () => {
    this.setState({
      ...this.state,
      linkToggled: true,
    });
  };
  // updateLink = () => {
  //   let val = document.querySelector("#meetingInput").value;
  //   if (!val) {
  //     toast.error(i18n("pleaseenterlink"));
  //     return false;
  //   }
  //   this.props.changeLessonlink(this.state.lesson._id, val);
  //   this.setState({
  //     ...this.state,
  //     linkToggled: false,
  //     lesson: {
  //       ...this.state.lesson,
  //       meetinglink: val,
  //     },
  //   });
  //   this.props.getCoachLessons();
  // };

  uploadFile = (e) => {
    let { files } = e.target;
    const s3config = {
      bucketName: "equireel-public",
      dirName: `lessons/${this.state.lesson._id}` /* optional */,
      region: "eu-west-1",
      accessKeyId: "AKIAJPM53KOXH2ISAHLQ",
      secretAccessKey: "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",
    };
    const ReactS3Client = new S3(s3config);
    this.setState({
      ...this.state,
      isUploading: true,
    });
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (file) {
        let fileName = file.name.replace(/\.[^/.]+$/, "");
        ReactS3Client.uploadFile(file, fileName)
          .then((data) => {
            this.props.addAttachmentFile(
              { lesson: this.state.lesson._id, file: data.location },
              "coach"
            );
            if (Number(index) === Number(files.length)) {
              this.setState({
                ...this.state,
                isUploading: false,
                uploadToggled: false,
              });
              this.forceUpdate();
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error(i18n("ue"));
            if (Number(index) === Number(files.length)) {
              this.setState({
                ...this.state,
                isUploading: false,
                uploadToggled: false,
              });
              this.forceUpdate();
            }
          });
      }
    }
    e.target.value = null;
  };

  deleteobject = (file) => {
    let confirmed = window.confirm(
      "Are you sure you want to delete this file from lesson?"
    );
    if (confirmed) {
      if (file.indexOf("coach-libraries") === -1) {
        const s3config = {
          bucketName: "equireel-public",
          region: "eu-west-1",
          accessKeyId: "AKIAJPM53KOXH2ISAHLQ",
          secretAccessKey: "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",
        };
        const ReactS3Client = new S3(s3config);
        ReactS3Client.deleteFile(
          file
            .split("https://equireel-public.s3-eu-west-1.amazonaws.com/")
            .pop()
        )
          .then((response) => {
            this.props.removeAttachmentFile(
              { lesson: this.state.lesson._id, file: file },
              "coach"
            );
            this.forceUpdate();
          })
          .catch((err) => {
            console.error(err);
            toast.error(i18n("ue"));
          });
      } else {
        this.props.removeAttachmentFile(
          { lesson: this.state.lesson._id, file: file },
          "coach"
        );
        this.forceUpdate();
      }
    }
    return false;
  };

  render() {
    const { lesson } = this.state;
    const fileColumns = [
      {
        name: "Name",
        selector: "key",
        sortable: true,
        cell: (row) => (
          <a href={row} target="_blank" rel="noopener noreferrer">
            {row.split(`/`).pop()}
          </a>
        ),
      },
      {
        name: "Actions",
        cell: (row) => (
          <span
            style={{
              color: "#fff",
              cursor: "pointer",
              marginLeft: "5px",
            }}
            onClick={() => {
              this.deleteobject(row);
              return false;
            }}
          >
            {i18n("delete")}
          </span>
        ),
      },
    ];
    const TextField = styled.input`
      height: 32px;
      width: 200px;
      border-radius: 3px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #e5e5e5;
      padding: 0 32px 0 16px;

      &:hover {
        cursor: pointer;
      }
    `;

    const ClearButton = styled.button`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      height: 34px;
      width: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    `;

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
      <>
        <TextField
          id="search"
          type="text"
          placeholder="Filter"
          value={filterText}
          onChange={onFilter}
        />
        <ClearButton onClick={onClear}>X</ClearButton>
      </>
    );
    const libraryColumns = [
      {
        name: "Name",
        selector: "key",
        sortable: true,
        cell: (row) => (
          <a href={row.location} target="_blank" rel="noopener noreferrer">
            {row.key
              .split(`coach-libraries/${this.state.auth.user._id}/`)
              .pop()}
          </a>
        ),
      },
      {
        name: "Actions",
        cell: (row) => (
          <span
            style={{
              color: "#fff",
              cursor: "pointer",
              marginLeft: "5px",
            }}
            onClick={() => {
              this.props.addAttachmentFile(
                { lesson: lesson._id, file: row.location },
                "coach"
              );
              this.setState({
                uploadToggled: false,
              });
              return false;
            }}
          >
            {i18n("addtolesson")}
          </span>
        ),
      },
    ];
    const LibraryTable = () => {
      const [filterText, setFilterText] = React.useState("");
      const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
      );
      const filteredItems = this.state.auth.user.attachmentlibrary.filter(
        (item) => item.key && item.key.includes(filterText)
      );

      const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
          if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
          }
        };

        return (
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
        );
      }, [filterText, resetPaginationToggle]);

      return (
        <DataTable
          columns={libraryColumns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          theme="dark"
        />
      );
    };
    return (
      <>
        {!this.state.is404 && this.state.auth.isAuthenticated && lesson && (
          <React.Fragment>
            <Helmet>
              <title>{i18n("lessontitle")}</title>
            </Helmet>
            {lesson && lesson.coach && (
              <Container className="lessonWrap">
                <Row>
                  <Col sm={12}>
                    <div className="backToLesson">
                      <Link to="/account/lessons/">
                        {i18n("backtoalllessons")}
                      </Link>
                    </div>
                  </Col>
                  <Col sm={12} md={8}>
                    <p>
                      <a href={`mailto:${lesson.user.email}`}>
                        {i18n("emailcustomer")}
                      </a>
                    </p>
                    <div className="fileSection">
                      <h2>{i18n("videoupload")}</h2>
                      <div className="actions">
                        {!this.state.uploadToggled && (
                          <div
                            className="addFile miniButton"
                            onClick={(e) =>
                              this.setState({
                                uploadToggled: "library",
                              })
                            }
                          >
                            {i18n("uploadvideofile")}
                          </div>
                        )}
                        {this.state.uploadToggled && (
                          <>
                            <div className="options">
                              {this.state.uploadToggled === "upload" && [
                                <div
                                  className="library miniButton"
                                  onClick={(e) =>
                                    this.setState({
                                      uploadToggled: "library",
                                    })
                                  }
                                >
                                  {i18n("fromlibrary")}
                                </div>,
                              ]}
                              {this.state.uploadToggled === "library" && [
                                <div
                                  className="upload miniButton"
                                  onClick={(e) =>
                                    this.setState({
                                      uploadToggled: "upload",
                                    })
                                  }
                                >
                                  {i18n("upload")}
                                </div>,
                              ]}
                            </div>
                            <div
                              className="backToList color-accent"
                              onClick={(e) =>
                                this.setState({
                                  uploadToggled: false,
                                })
                              }
                            >
                              {i18n("backtofileslist")}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="optionsContent">
                        {this.state.uploadToggled === "library" && (
                          <>
                            <div className="library">
                              <h4>{i18n("addfilefromlibrary")}</h4>
                              <LibraryTable />
                            </div>
                          </>
                        )}
                        {this.state.uploadToggled === "upload" &&
                          !this.state.isUploading && (
                            <div className="upload">
                              <h4>{i18n("uploadfile")}</h4>
                              <div>
                                <input
                                  type="file"
                                  name="file"
                                  onChange={this.uploadFile}
                                />
                                <small>{i18n("uploadfileshere")}</small>
                              </div>
                            </div>
                          )}
                        {this.state.uploadToggled === "upload" &&
                          this.state.isUploading && (
                            <div>
                              <small>{i18n("uploadingplzwait")}</small>
                            </div>
                          )}
                      </div>
                      {!this.state.uploadToggled && (
                        <DataTable
                          columns={fileColumns}
                          data={lesson.files}
                          theme="dark"
                        />
                      )}
                    </div>
                    <div className="commentsSection">
                      <h2>{i18n("comments")}</h2>
                      <p>
                        {lesson.usercomments
                          ? lesson.usercomments
                          : i18n("notprovidedyet")}
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="card alignedTable detailsTable">
                      <div className="cardTitle">{i18n("lessondetails")}</div>
                      <div className="cardContent infoList withPadding bg-secondary">
                        <ul className="contentList">
                          <li>
                            <div className="label">{i18n("charged")}</div>
                            <div className="value">
                              <div className="price">
                                <span className="value">
                                  {lesson.charged.toFixed(2)}
                                </span>
                                <span className="currency">
                                  {lesson.currency}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="label">{i18n("slotdate")}</div>
                            <div className={`value`}>
                              <Moment format="DD/MM/YYYY" tz="Europe/London">
                                {lesson.bookingslot}
                              </Moment>
                            </div>
                          </li>
                          <li>
                            <div className="label">{i18n("slottime")}</div>
                            <div className={`value`}>
                              <Moment format="hh:mm A" tz="Europe/London">
                                {lesson.bookingslot}
                              </Moment>
                            </div>
                          </li>
                          <li>
                            <div className="label">{i18n("bookedon")}</div>
                            <div className={`value`}>
                              <Moment
                                format="dddd DD/MM/YYYY"
                                tz="Europe/London"
                              >
                                {lesson.date}
                              </Moment>
                            </div>
                          </li>
                          <li>
                            <div className="label">{i18n("status")}</div>
                            {!this.state.inputToggled && (
                              <div
                                className={`value ${
                                  lesson.status === "In Progress" ||
                                  lesson.status === "Confirmed"
                                    ? "text-info"
                                    : lesson.status === "Completed"
                                    ? "text-success"
                                    : lesson.status === "Awaiting Upload"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {lesson.status}
                                <span
                                  className="color-accent"
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "8px",
                                  }}
                                  onClick={this.showChange}
                                >
                                  {i18n("change")}
                                </span>
                              </div>
                            )}
                            {this.state.inputToggled && (
                              <div className={`value`}>
                                <select
                                  onChange={(e) =>
                                    this.changeStatus(e.target.value)
                                  }
                                  className="form-control"
                                  defaultValue={lesson.status}
                                >
                                  <option value="In Progress">
                                    In Progress
                                  </option>
                                  <option value="Awaiting Upload">
                                    Awaiting Upload
                                  </option>
                                  <option value="Confirmed">Confirmed</option>
                                  <option value="Completed">Completed</option>
                                  <option value="Cancelled">Cancelled</option>
                                </select>
                              </div>
                            )}
                          </li>
                          <li>
                            <div className="label">{i18n("meetinglink")}</div>
                            <div className={`value`}>
                              {lesson.coach.zoom &&
                                lesson.coach.zoom.meetinglink && (
                                  <a
                                    href={lesson.coach.zoom.meetinglink}
                                    target="_blank"
                                    className="nostyle"
                                    rel="noopener noreferrer"
                                  >
                                    {i18n("clickhere")}
                                  </a>
                                )}
                              {lesson.coach.zoom &&
                                !lesson.coach.zoom.meetinglink &&
                                i18n("notprovidedyet")}
                              {!lesson.coach.zoom && i18n("notprovidedyet")}
                            </div>
                          </li>
                          <li>
                            <div className="label">{i18n("meetingid")}</div>
                            <div className={`value`}>
                              {lesson.coach.zoom
                                ? lesson.coach.zoom.meetingid
                                  ? lesson.coach.zoom.meetingid
                                  : i18n("notprovidedyet")
                                : i18n("notprovidedyet")}
                            </div>
                          </li>
                          <li>
                            <div className="label">
                              {i18n("meetingpassword")}
                            </div>
                            <div className={`value`}>
                              {lesson.coach.zoom
                                ? lesson.coach.zoom.meetingpassword
                                  ? lesson.coach.zoom.meetingpassword
                                  : i18n("notprovidedyet")
                                : i18n("notprovidedyet")}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </React.Fragment>
        )}
        {!lesson && (
          <Container className="fullScreenCenter vh70">
            <h1>{i18n("loading")}...</h1>
            <div className="spacer"></div>
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  coach: state.coach,
});

CoachLesson.propTypes = {
  auth: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  getCoachLessons: PropTypes.func.isRequired,
  changeLessonStatus: PropTypes.func.isRequired,
  // changeLessonlink: PropTypes.func.isRequired,
  addAttachmentFile: PropTypes.func.isRequired,
  removeAttachmentFile: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  setInfo,
  getCoachLessons,
  changeLessonStatus,
  // changeLessonlink,
  addAttachmentFile,
  removeAttachmentFile,
})(withRouter(CoachLesson));
