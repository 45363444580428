/* global i18n */
import { GET_RECORDS } from "../types";
import axios from "axios";
import { toast } from "react-toastify";

export const getRecords = () => dispatch => {
    axios
        .get(process.env.REACT_APP_API_PATH + "records/get")
        .then(res => {
            dispatch({
                type: GET_RECORDS,
                payload: res.data
            });
        })
        .catch(err => {
            let e = err;
            if (!e) {
                e = i18n('ue');
            }
            if (typeof e === "object") {
              e = e.message;
            }
            toast.error(e);
        });
};