/* global i18n */
import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { isEqual } from "date-fns";
import TextAreaAutosize from "react-textarea-autosize";
import DateTimePicker from "react-datetime-picker";
import Moment from "react-moment";
import "moment-timezone";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import S3 from "react-aws-s3";

import { checkMinLength } from "../../inc/Validation";
import {
  changePassword,
  editCoach,
  updateAvailability,
  unsetUser,
  addAttachmentFile,
  removeAttachmentFile,
} from "../../inc/actions/authActions";
import { getCoachLessons } from "../../inc/actions/bookingActions";

class CoachAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      userOrders: [],
      fname: "",
      lname: "",
      auth: {},
      initialized: false,
      bio: "",
      fee: null,
      meetinglink: "",
      meetingid: "",
      meetingpassword: "",
      coachLessons: [],
      availability: [],
      tempDateTime: null,
      attachmentlibrary: [],
      uploadToggled: false,
      isUploading: false,
      changed: false,
    };
    this.props.getCoachLessons();
    if (this.state.changed) {
      console.log("blocked");
      this.props.history.block();
    }
    // let history = this.props.history;
    // history.listenBefore((location, done) => {
    // });
    // this.props.history.listen((location, action) => {
    // });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.cart) {
        if (nextProps.cart.userOrders) {
          update.userOrders = nextProps.cart.userOrders;
        }
      }
      if (nextProps.coach) {
        if (nextProps.coach.coachLessons) {
          update.coachLessons = nextProps.coach.coachLessons;
        }
      }
      if (nextProps.auth) {
        update.auth = nextProps.auth;
        if (update.auth.user.fname && prevProps.initialized === false) {
          update.initialized = true;
          update.fname = nextProps.auth.user.fname;
          update.lname = nextProps.auth.user.lname;
          update.bio = nextProps.auth.user.bio;
          update.fee = nextProps.auth.user.fee;
          if (!nextProps.auth.user.zoom) {
            nextProps.auth.user.zoom = {
              meetinglink: "",
              meetingid: "",
              meetingpassword: "",
            };
          }
          update.meetinglink = nextProps.auth.user.zoom.meetinglink;
          update.meetingid = nextProps.auth.user.zoom.meetingid;
          update.meetingpassword = nextProps.auth.user.zoom.meetingpassword;
          update.availability = [];
          if (nextProps.auth.user.availability) {
            nextProps.auth.user.availability.forEach((date) => {
              update.availability.push(new Date(date));
            });
          }
        }
        if (nextProps.auth.user.attachmentlibrary) {
          update.attachmentlibrary = nextProps.auth.user.attachmentlibrary
            ? nextProps.auth.user.attachmentlibrary
            : [];
        }
      }
      if (nextProps.page) {
        update.page = nextProps.page;
      }
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push(`/login/?returnto=/account/${nextProps.page}`);
      }
      return update;
    } else {
      return null;
    }
  }
  changeHandler = (e, iselect) => {
    if (!iselect) {
      this.setState({ [e.target.name]: e.target.value, changed: true });
    } else {
      if (e.value) {
        this.setState({ [e.name]: e.value, changed: true });
      } else {
        this.setState({ [e.name]: "", changed: true });
      }
    }
  };
  submitPasschangeHandler = (e) => {
    e.preventDefault();
    let errorMsg = i18n("ue");
    let { currentPassword, newPassword } = this.state;
    if (!currentPassword || !checkMinLength(currentPassword, 6)) {
      errorMsg = i18n("invalidCurrentPassword");
      toast.error(errorMsg);
      return false;
    }
    if (!newPassword || !checkMinLength(newPassword, 6)) {
      errorMsg = i18n("passwordatleast");
      toast.error(errorMsg);
      return false;
    }
    this.setState({
      ...this.state,
      changed: false,
    });
    this.props.changePassword({ currentPassword, newPassword });
    return false;
  };
  logoutUser = (e) => {
    e.preventDefault();
    this.props.unsetUser("/", this.props.history);
    return false;
  };
  validate = () => {
    var errorMsg = "";
    const { fname, lname, bio, fee } = this.state;
    if (!fname || !checkMinLength(fname, 3)) {
      errorMsg = i18n("lengthfname");
      toast.error(errorMsg);
      return false;
    }
    if (!lname || !checkMinLength(lname, 3)) {
      errorMsg = i18n("lengthlname");
      toast.error(errorMsg);
      return false;
    }
    if (!bio || !checkMinLength(bio, 10)) {
      errorMsg = i18n("lengthbio");
      toast.error(errorMsg);
      return false;
    }
    if (!fee || Number(fee) < 1) {
      errorMsg = i18n("feelengtherror");
      toast.error(errorMsg);
      return false;
    }
    return true;
  };
  EditProfileSubmitHandler = (e) => {
    e.preventDefault();
    let {
      fname,
      lname,
      bio,
      fee,
      meetinglink,
      meetingid,
      meetingpassword,
    } = this.state;
    if (this.validate()) {
      console.log("form validated");

      const User = {
        fname,
        lname,
        bio,
        fee,
        meetinglink,
        meetingid,
        meetingpassword,
      };

      this.props.editCoach(User, this.props.history);
      this.setState({
        ...this.state,
        changed: false,
      });
    } else {
      return false;
    }
  };
  changeAvailability = (e) => {
    e.preventDefault();
    let { availability } = this.state;
    this.props.updateAvailability(availability);
    this.setState({
      ...this.state,
      changed: false,
    });
  };
  onDateTimeChange = (date) => {
    this.setState({ ...this.state, tempDateTime: date });
  };
  addSlot = () => {
    let { tempDateTime, availability } = this.state;
    if (!tempDateTime) {
      toast.error(i18n("selectslotfirst"));
      return false;
    }
    tempDateTime = new Date(
      tempDateTime.getFullYear(),
      tempDateTime.getMonth(),
      tempDateTime.getDate(),
      tempDateTime.getHours(),
      tempDateTime.getMinutes(),
      0
    );
    for (let index = 0; index < availability.length; index++) {
      const date = availability[index];
      if (isEqual(date, tempDateTime)) {
        toast.error(i18n("slotalreadyselected"));
        return false;
      }
    }
    availability.push(tempDateTime);
    this.setState({
      ...this.state,
      availability: availability,
      tempDateTime: null,
      changed: true,
    });
  };
  deleteSlot = (e) => {
    let key = e.target.getAttribute("data-key");
    let availability = this.state.availability;
    availability.splice(Number(key), 1);
    this.setState({
      ...this.state,
      availability: availability,
      changed: true,
    });
  };
  deleteobject = (file) => {
    let confirmed = window.confirm(
      "Are you sure you want to delete this file from library?"
    );
    if (confirmed) {
      const s3config = {
        bucketName: "equireel-public",
        // dirName: `` /* optional */,
        region: "eu-west-1",
        accessKeyId: "AKIAJPM53KOXH2ISAHLQ",
        secretAccessKey: "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",
      };
      const ReactS3Client = new S3(s3config);
      ReactS3Client.deleteFile(file.key)
        .then((response) => {
          this.props.removeAttachmentFile(file);
          this.forceUpdate();
        })
        .catch((err) => {
          console.error(err);
          toast.error(i18n("ue"));
        });
    }
    return false;
  };
  toggleUploadField = () => {
    this.setState({
      ...this.state,
      uploadToggled: !this.state.uploadToggled,
    });
  };
  uploadFile = (e) => {
    let { files } = e.target;
    const s3config = {
      bucketName: "equireel-public",
      dirName: `coach-libraries/${this.state.auth.user._id}` /* optional */,
      region: "eu-west-1",
      accessKeyId: "AKIAJPM53KOXH2ISAHLQ",
      secretAccessKey: "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",
    };
    const ReactS3Client = new S3(s3config);
    this.setState({
      ...this.state,
      isUploading: true,
    });
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (file) {
        let fileName = file.name.replace(/\.[^/.]+$/, "");
        ReactS3Client.uploadFile(file, fileName)
          .then((data) => {
            this.props.addAttachmentFile(data);
            toast.success(i18n("fileuploaded"));
            if (Number(index) === Number(files.length)) {
              this.setState({
                ...this.state,
                isUploading: false,
              });
              this.forceUpdate();
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error(i18n("ue"));
            if (Number(index) === Number(files.length)) {
              this.setState({
                ...this.state,
                isUploading: false,
              });
              this.forceUpdate();
            }
          });
      }
    }
    e.target.value = null;
  };
  render() {
    const TextField = styled.input`
      height: 32px;
      width: 200px;
      border-radius: 3px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #e5e5e5;
      padding: 0 32px 0 16px;

      &:hover {
        cursor: pointer;
      }
    `;

    const ClearButton = styled.button`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      height: 34px;
      width: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    `;

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
      <>
        <TextField
          id="search"
          type="text"
          placeholder="Filter"
          value={filterText}
          onChange={onFilter}
        />
        <ClearButton onClick={onClear}>X</ClearButton>
      </>
    );
    const columns = [
      {
        name: "Name",
        selector: "key",
        sortable: true,
        cell: (row) => (
          <a href={row.location} target="_blank" rel="noopener noreferrer">
            {row.key
              .split(`coach-libraries/${this.state.auth.user._id}/`)
              .pop()}
          </a>
        ),
      },
      {
        name: "Actions",
        cell: (row) => (
          <span
            style={{
              color: "#fff",
              cursor: "pointer",
              marginLeft: "5px",
            }}
            onClick={() => {
              this.deleteobject(row);
              return false;
            }}
          >
            {i18n("delete")}
          </span>
        ),
      },
    ];
    const BasicTable = () => {
      const [filterText, setFilterText] = React.useState("");
      const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
      );
      const filteredItems = this.state.attachmentlibrary.filter(
        (item) => item.key && item.key.includes(filterText)
      );

      const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
          if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
          }
        };

        return (
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
        );
      }, [filterText, resetPaginationToggle]);

      return (
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          theme="dark"
        />
      );
    };
    return (
      <>
        {!this.state.is404 && this.state.auth.isAuthenticated && (
          <React.Fragment>
            <Helmet>
              <title>{i18n("accountTitle")}</title>
            </Helmet>
            <Container className="accountWrap">
              <Row>
                <Col sm={12} md={3}>
                  <div className="accountNavWrap">
                    <ul className="accountNav">
                      <li>
                        <Link
                          to="/account/dashboard/"
                          className={
                            this.props.page === "dashboard" ? "active" : ""
                          }
                        >
                          {i18n("dashboard")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/account/calendar/"
                          className={
                            this.props.page === "calendar" ? "active" : ""
                          }
                        >
                          {i18n("editcalendar")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/account/lessons/"
                          className={
                            this.props.page === "lessons" ? "active" : ""
                          }
                        >
                          {i18n("viewlessons")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/account/attachmentlibrary/"
                          className={
                            this.props.page === "attachmentlibrary"
                              ? "active"
                              : ""
                          }
                        >
                          {i18n("attachmentlibrary")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/account/profile/"
                          className={
                            this.props.page === "profile" ? "active" : ""
                          }
                        >
                          {i18n("editAccount")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/account/password/"
                          className={
                            this.props.page === "password" ? "active" : ""
                          }
                        >
                          {i18n("changePassword")}
                        </Link>
                      </li>
                      <li onClick={this.logoutUser}>
                        <Link to="/">{i18n("logout")}</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col sm={12} md={9}>
                  <div className="pageContentWrap">
                    {this.props.page === "dashboard" && (
                      <div className="pageContent dashboardContent card">
                        <h2 className="contentTitle cardTitle">
                          {i18n("dashboard")}
                        </h2>
                        <div className="content cardContent withPadding">
                          <p>
                            <span className="boldfamily">
                              {i18n("hi")} {this.state.auth.user.name}!
                            </span>{" "}
                            {i18n("canManageAccountHere")}{" "}
                            <Link to="/account/lessons/">
                              {i18n("seeYourLessons")}
                            </Link>{" "}
                            {i18n("andMore")}.
                          </p>
                        </div>
                      </div>
                    )}
                    {this.props.page === "calendar" && (
                      <div className="pageContent calendarContent card">
                        <h2 className="contentTitle cardTitle expand">
                          {i18n("calendar")}
                          <button
                            onClick={this.changeAvailability}
                            className="button cardAction"
                          >
                            {i18n("save")}
                          </button>
                        </h2>
                        <div className="content cardContent withPadding">
                          <Row>
                            <Col sm={12} md={6}>
                              <p style={{ padding: "5px 10px" }}>
                                {i18n("selectavailabilityrange")}
                              </p>
                              <DateTimePicker
                                onChange={this.onDateTimeChange}
                                value={this.state.tempDateTime}
                                format={"y/MM/dd hh:mm a"}
                                minDate={new Date()}
                              />
                              <button
                                className="button btn-sm"
                                style={{ marginLeft: "20px" }}
                                onClick={this.addSlot}
                              >
                                {i18n("addslot")}
                              </button>
                            </Col>
                            <Col sm={12} md={6}>
                              <p style={{ padding: "5px 10px" }}>
                                {this.state.availability.length > 0 &&
                                  i18n("createdslots")}
                                {this.state.availability.length < 1 &&
                                  i18n("noslotsyet")}
                              </p>
                              <ul className="slots">
                                {this.state.availability.map((slot, i) => [
                                  <li key={i}>
                                    <Moment
                                      date={slot}
                                      tz="Europe/London"
                                      format={"dddd YYYY/MM/DD hh:mm a"}
                                    />
                                    <span
                                      className="color-accent"
                                      style={{
                                        cursor: "pointer",
                                        float: "right",
                                      }}
                                      onClick={this.deleteSlot}
                                      data-key={i}
                                    >
                                      {i18n("delete")}
                                    </span>
                                  </li>,
                                ])}
                              </ul>
                            </Col>
                            <Col sm={12}>
                              <br />
                              <br />
                              <p>{i18n("dontforgettosavechanges")}</p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                    {this.props.page === "lessons" && (
                      <div className="pageContent lessonsContent card">
                        <h2 className="contentTitle cardTitle">
                          {i18n("lessons")}
                        </h2>
                        <div
                          className="content cardContent bg-primary"
                          style={{ overflow: "scroll" }}
                        >
                          {this.state.coachLessons &&
                            this.state.coachLessons.length > 0 && (
                              <Table striped>
                                <thead>
                                  <tr>
                                    <th>{i18n("user")}</th>
                                    <th>{i18n("date")}</th>
                                    <th>{i18n("status")}</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.coachLessons.map((lesson) => [
                                    <tr key={lesson._id}>
                                      <td>{`${lesson.user.fname} ${lesson.user.lname}`}</td>
                                      <td>
                                        <Moment
                                          format="dddd MMMM Do, YYYY hh:mm A"
                                          date={new Date(lesson.bookingslot)}
                                          tz="Europe/London"
                                        />
                                      </td>
                                      <td>
                                        {lesson.status === "progress"
                                          ? `${i18n("inprogress")}`
                                          : lesson.status}
                                      </td>
                                      <td style={{ minWidth: "200px" }}>
                                        <Link
                                          to={`/lesson/${lesson._id}`}
                                          style={{
                                            background: "var(--accent-color)",
                                            color: "#fff",
                                            padding: "3px 10px",
                                            borderRadius: "50px",
                                          }}
                                        >
                                          {i18n("viewupload")}
                                        </Link>
                                      </td>
                                    </tr>,
                                  ])}
                                </tbody>
                              </Table>
                            )}
                          {this.state.coachLessons.length === 0 && (
                            <p style={{ padding: "20px" }}>
                              {i18n("lessonswillshowuphere")}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    {this.props.page === "attachmentlibrary" && (
                      <div className="pageContent attachmentlibraryContent card">
                        <h2 className="contentTitle cardTitle expand">
                          {i18n("attachmentlibrary")}
                          {!this.state.uploadToggled && (
                            <button
                              onClick={this.toggleUploadField}
                              className="button cardAction"
                            >
                              {i18n("upload")}
                            </button>
                          )}
                          {this.state.uploadToggled && (
                            <button
                              onClick={this.toggleUploadField}
                              className="button cardAction"
                            >
                              {i18n("viewlibrary")}
                            </button>
                          )}
                        </h2>
                        <div className="content cardContent">
                          {!this.state.uploadToggled && <BasicTable />}
                          {this.state.uploadToggled && !this.state.isUploading && (
                            <div style={{ padding: "20px" }}>
                              <input
                                type="file"
                                name="file"
                                onChange={this.uploadFile}
                              />
                              <small>{i18n("uploadfileshere")}</small>
                            </div>
                          )}
                          {this.state.uploadToggled && this.state.isUploading && (
                            <div style={{ padding: "20px" }}>
                              <small>{i18n("uploadingplzwait")}</small>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {this.props.page === "profile" && (
                      <div className="pageContent passwordContent card">
                        <h2 className="contentTitle cardTitle">
                          {i18n("editAccount")}
                        </h2>
                        <div className="content cardContent withPadding">
                          <form
                            action="post"
                            onSubmit={this.EditProfileSubmitHandler}
                          >
                            <input
                              onChange={this.changeHandler}
                              name="fname"
                              className="form-control fname"
                              type="text"
                              placeholder={i18n("fname")}
                              value={this.state.fname}
                              style={{ width: "50%", display: "inline-block" }}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="lname"
                              className="form-control lname"
                              type="text"
                              placeholder={i18n("lname")}
                              value={this.state.lname}
                              style={{ width: "50%", display: "inline-block" }}
                            />
                            <TextAreaAutosize
                              onChange={(e) => {
                                this.setState({
                                  ...this.state,
                                  bio: e.target.value,
                                });
                              }}
                              name="bio"
                              className="form-control bio"
                              placeholder={i18n("bio")}
                              value={this.state.bio}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="fee"
                              className="form-control fee"
                              type="number"
                              placeholder={i18n("lessonfee")}
                              value={this.state.fee}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="meetinglink"
                              className="form-control meetinglink"
                              type="text"
                              placeholder={i18n("meetinglink")}
                              value={this.state.meetinglink}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="meetingid"
                              className="form-control meetingid"
                              type="text"
                              placeholder={i18n("meetingid")}
                              value={this.state.meetingid}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="meetingpassword"
                              className="form-control meetingpassword"
                              type="text"
                              placeholder={i18n("meetingpassword")}
                              value={this.state.meetingpassword}
                            />
                            <input
                              className="button bg-accent"
                              type="submit"
                              value={i18n("save")}
                            />
                          </form>
                        </div>
                      </div>
                    )}
                    {this.props.page === "password" && (
                      <div className="pageContent passwordContent card">
                        <h2 className="contentTitle cardTitle">
                          {i18n("changePassword")}
                        </h2>
                        <div className="content cardContent withPadding">
                          <form
                            action="post"
                            onSubmit={this.submitPasschangeHandler}
                          >
                            <input
                              onChange={this.changeHandler}
                              name="currentPassword"
                              className="form-control currentPassword"
                              type="password"
                              placeholder={i18n("currentPassword")}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="newPassword"
                              className="form-control newPassword"
                              type="password"
                              placeholder={i18n("newpassword")}
                            />
                            <input
                              className="button bg-accent"
                              type="submit"
                              value={i18n("changePassword")}
                            />
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        )}
        {this.state.is404 && (
          <>
            <h1 className="text-center">404 :(</h1>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  cart: state.cart,
  coach: state.coach,
});

CoachAccount.propTypes = {
  auth: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
  editCoach: PropTypes.func.isRequired,
  updateAvailability: PropTypes.func.isRequired,
  getCoachLessons: PropTypes.func.isRequired,
  unsetUser: PropTypes.func.isRequired,
  addAttachmentFile: PropTypes.func.isRequired,
  removeAttachmentFile: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  changePassword,
  editCoach,
  updateAvailability,
  getCoachLessons,
  unsetUser,
  addAttachmentFile,
  removeAttachmentFile,
})(withRouter(CoachAccount));
