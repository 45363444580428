/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { setInfo } from "../inc/actions/infoActions";

const Error = (props) => {
    props.setInfo({
        for: "currentPage",
        data: "404"
    });

    return (
      <>
        <Helmet>
          <title>{`Error`}</title>
        </Helmet>
        <Container className="Wrap404 vh70 fullscreenCenter">
          <Row>
            <Col>
              <h1 className="megaTitle">{i18n("sthwentwrong")}</h1>
              <h2>
                {i18n("reloadthepage")} {i18n("or")} <Link to="/">{i18n("gobackhome")}</Link>
              </h2>
            </Col>
          </Row>
        </Container>
      </>
    );
}

Error.propTypes = {
    setInfo: PropTypes.func.isRequired
};

export default connect(null, { setInfo })(
    withRouter(Error)
);
