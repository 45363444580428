import { combineReducers } from "redux";
import authReducer from "./authReducer";
import infoReducer from "./infoReducer";
import recordsReducer from "./recordsReducer";
import cartReducer from "./cartReducer";
import coachReducer from "./coachReducer";
import supportReducer from "./supportReducer";

export default combineReducers({
  auth: authReducer,
  info: infoReducer,
  records: recordsReducer,
  cart: cartReducer,
  coach: coachReducer,
  support: supportReducer,
});
