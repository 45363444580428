/* global i18n */
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";

import CustomerAccount from "./Account/CustomerAccount";
import CoachAccount from "./Account/CoachAccount";
import { setInfo } from "../inc/actions/infoActions";

class Account extends React.Component {
  constructor(props) {
    super(props);
    let page = props.page;
    page = page ? page : "dashboard";
    if (
      page !== "dashboard" &&
      page !== "orders" &&
      page !== "support" &&
      page !== "password" &&
      page !== "profile" &&
      page !== "calendar" &&
      page !== "lessons" &&
      page !== "attachmentlibrary" &&
      page !== "faq"
    ) {
      props.history.push("/404");
    }
    this.state = {
      page,
      is404:
        page === "dashboard" ||
        page === "orderDetails" ||
        page === "orders" ||
        page === "support" ||
        page === "password" ||
        page === "profile" ||
        page === "calendar" ||
        page === "lessons" ||
        page === "attachmentlibrary" ||
        page === "faq"
          ? false
          : true,
      auth: {},
      initialized: false,
    };
    this.props.setInfo({
      for: "currentPage",
      data: "account",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.page) {
        update.page = nextProps.page;
      }
      if (nextProps.auth) {
        update.auth = nextProps.auth;
        if (update.auth.user.fname && prevProps.initialized === false) {
          update.auth = nextProps.auth;
        }
      }
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push(
          `/login/?returnto=/account/${nextProps.page ? nextProps.page : ""}`
        );
      }
      return update;
    } else {
      return null;
    }
  }
  render() {
    return (
      <>
        {!this.state.is404 && this.state.auth.isAuthenticated && (
          <React.Fragment>
            <Helmet>
              <title>{i18n("accountTitle")}</title>
            </Helmet>
            {this.state.auth.isAuthenticated &&
              this.state.auth.user.type === "customer" && (
                <CustomerAccount page={this.state.page} />
              )}
            {this.state.auth.isAuthenticated &&
              this.state.auth.user.type === "coach" && (
                <CoachAccount page={this.state.page} />
              )}
          </React.Fragment>
        )}
        {this.state.is404 && (
          <>
            <h1 className="text-center">404 :(</h1>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
});

Account.propTypes = {
  setInfo: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setInfo })(withRouter(Account));
