/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
// import Slider from "react-rangeslider";
import { addDays, isBefore, isEqual, formatDistanceToNow } from "date-fns";

// import "react-rangeslider/lib/index.css";
import { setInfo } from "../inc/actions/infoActions";
import { getCoaches } from "../inc/actions/bookingActions";

class CoachListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRange: 100,
      coaches: [],
      filteredCoaches: [],
      initialized: false,
      currency: "Pound",
    };
    this.props.setInfo({
      for: "currentPage",
      data: "coachListing",
    });
    this.props.getCoaches();
  }
  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.auth.user && nextProps.auth.user.type === "coach") {
      return nextProps.history.push(`/account/`);
    }
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.coach) {
        if (nextProps.coach.coaches) {
          if (
            prevProps.initialized === false &&
            nextProps.coach.coaches.length > 0
          ) {
            let coaches = nextProps.coach.coaches;
            for (let index = 0; index < coaches.length; index++) {
              coaches[index].availability = coaches[index].availability.filter(
                function (date) {
                  if (isBefore(new Date(date), new Date())) {
                    return false;
                  } else {
                    return true;
                  }
                }
              );
              coaches[index].availability.sort(function (a, b) {
                return new Date(a) - new Date(b);
              });
            }
            coaches = coaches.sort(function (a, b) {
              let num1 = Number(a.fname.split(".")[0]);
              let num2 = Number(b.fname.split(".")[0]);
              return num1 - num2;
            });
            update.coaches = coaches;
            const currentDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            );
            // min range
            // const allDates = coaches.map((coach) => {
            //   for (let index = 0; index < coach.availability.length; index++) {
            //     const element = coach.availability[index];
            //     if (element) {
            //       return new Date(element);
            //     }
            //   }
            // });
            // allDates.splice(Number(allDates.length - 1), 1);
            // const minDate = closestTo(currentDate, allDates);
            // const Difference_In_Time =
            //   minDate.getTime() - currentDate.getTime();
            // const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            // filter array
            const filteredCoaches = coaches.filter(function (coach) {
              let slots = coach.availability;
              for (let index = 0; index < slots.length; index++) {
                let slot = slots[index];
                slot = new Date(
                  new Date(slot).getFullYear(),
                  new Date(slot).getMonth(),
                  new Date(slot).getDate()
                );
                if (
                  isEqual(addDays(currentDate, 100), slot) ||
                  isBefore(slot, addDays(currentDate, 100))
                ) {
                  return true;
                }
              }
              return false;
            });
            update.filteredCoaches = filteredCoaches;
            update.initialized = true;
          }
        }
      }
      return update;
    } else {
      return null;
    }
  }

  handleRangeChange = (value) => {
    const currentDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    const coaches = this.state.coaches;
    const filteredCoaches = coaches.filter(function (coach) {
      let slots = coach.availability;
      for (let index = 0; index < slots.length; index++) {
        let slot = slots[index];
        slot = new Date(
          new Date(slot).getFullYear(),
          new Date(slot).getMonth(),
          new Date(slot).getDate()
        );
        if (
          isEqual(addDays(currentDate, value), slot) ||
          isBefore(slot, addDays(currentDate, value))
        ) {
          return true;
        }
      }
      return false;
    });
    this.setState({
      selectedRange: value,
      filteredCoaches: filteredCoaches,
    });
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{`${i18n("coaches")} - Equireel`}</title>
        </Helmet>
        <Container className="coachListingWrap">
          <Row>
            <Col>
              <h1 className="text-center pageTitle">{i18n("ourcoaches")}</h1>
              <Row>
                <Col sm={12} md={3}></Col>
                <Col sm={12} md={6}>
                  <div style={{ height: "20px" }}></div>
                  {/* <Slider
                    value={this.state.selectedRange}
                    onChange={this.handleRangeChange}
                    min={0}
                    max={100}
                    tooltip={false}
                  /> */}
                  <p className="text-center">
                    {this.state.selectedRange > 0
                      ? `${i18n("lessonavailablewithin")} ${
                          this.state.selectedRange
                        } ${
                          this.state.selectedRange > 1
                            ? i18n("days")
                            : i18n("day")
                        }`
                      : i18n("lessonavailabletoday")}
                  </p>
                </Col>
                <Col sm={12} md={3}></Col>
                <Col sm={12}>
                  {this.state.filteredCoaches &&
                    this.state.filteredCoaches.length > 0 && (
                      <div className="coaches">
                        <Row>
                          {this.state.filteredCoaches.map((coach) => [
                            <Col sm={6} md={3} key={coach._id}>
                              <div className="coach">
                                <div className="image">
                                  <img
                                    src={`https://equireel-public.s3.amazonaws.com/coach-dp/${coach.dp}`}
                                    className="img-fluid"
                                    alt={coach.fname + " " + coach.lname}
                                  />
                                </div>
                                <div className="name">
                                  {coach.fname + " " + coach.lname}
                                </div>
                                <div className="availability">
                                  {i18n("nextavailableslot")}{" "}
                                  {new Date(coach.availability[0]) -
                                    new Date() <=
                                  0
                                    ? i18n("today")
                                    : formatDistanceToNow(
                                        new Date(
                                          new Date(
                                            coach.availability[0]
                                          ).getFullYear(),
                                          new Date(
                                            coach.availability[0]
                                          ).getMonth(),
                                          new Date(
                                            coach.availability[0]
                                          ).getDate(),
                                          new Date(
                                            coach.availability[0]
                                          ).getHours(),
                                          new Date(
                                            coach.availability[0]
                                          ).getMinutes(),
                                          0
                                        ),
                                        { addSuffix: true }
                                      ).indexOf("about") === -1
                                    ? formatDistanceToNow(
                                        new Date(
                                          new Date(
                                            coach.availability[0]
                                          ).getFullYear(),
                                          new Date(
                                            coach.availability[0]
                                          ).getMonth(),
                                          new Date(
                                            coach.availability[0]
                                          ).getDate(),
                                          new Date(
                                            coach.availability[0]
                                          ).getHours(),
                                          new Date(
                                            coach.availability[0]
                                          ).getMinutes(),
                                          0
                                        ),
                                        { addSuffix: true }
                                      ).replace("in", "in about")
                                    : formatDistanceToNow(
                                        new Date(
                                          new Date(
                                            coach.availability[0]
                                          ).getFullYear(),
                                          new Date(
                                            coach.availability[0]
                                          ).getMonth(),
                                          new Date(
                                            coach.availability[0]
                                          ).getDate(),
                                          new Date(
                                            coach.availability[0]
                                          ).getHours(),
                                          new Date(
                                            coach.availability[0]
                                          ).getMinutes(),
                                          0
                                        ),
                                        { addSuffix: true }
                                      )}
                                </div>
                                <div className="fee">
                                  <div className="price">
                                    {`${i18n("lessonfee")}: `}
                                    <span className="value">
                                      {coach.fee.toFixed(2)}
                                    </span>
                                    <span className="currency">
                                      {this.state.currency}
                                    </span>
                                  </div>
                                </div>
                                <div className="bookButton">
                                  <Link
                                    to={`/coach/${coach._id}`}
                                    className="button"
                                  >
                                    {i18n("booknow")}
                                  </Link>
                                </div>
                              </div>
                            </Col>,
                          ])}
                        </Row>
                      </div>
                    )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  coach: state.coach,
});

CoachListing.propTypes = {
  setInfo: PropTypes.func.isRequired,
  getCoaches: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setInfo, getCoaches })(
  withRouter(CoachListing)
);
