/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import "moment-timezone";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import S3 from "react-aws-s3";
import { addDays, format } from "date-fns";
import TextareaAutosize from "react-textarea-autosize";

import { setInfo } from "../../inc/actions/infoActions";
import {
  getUserLessons,
  addAttachmentFile,
  removeAttachmentFile,
  getCoaches,
  changeLessonComments,
} from "../../inc/actions/bookingActions";

class UserLesson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is404: false,
      lesson: {},
      got: false,
      uploadToggled: false,
      isUploading: false,
      commentsToggled: false,
    };
    this.props.setInfo({
      for: "currentPage",
      data: "account",
    });
    this.props.getUserLessons();
    this.props.getCoaches();
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.id) {
        if (nextProps.coach) {
          if (
            nextProps.coach.initialized.indexOf("userLessons") !== -1 &&
            nextProps.coach.initialized.indexOf("coaches") !== -1
          ) {
            if (nextProps.coach.userLessons && nextProps.coach.coaches) {
              update.userLessons = nextProps.coach.userLessons;
              let found = false;
              update.got = true;
              for (let index = 0; index < update.userLessons.length; index++) {
                let userLesson = update.userLessons[index];
                if (userLesson._id === nextProps.id) {
                  update.id = nextProps.id;
                  update.is404 = false;
                  found = true;
                  update.lesson = userLesson;
                }
              }
              if (!found && update.got) {
                console.log("redirecting to 404", 1);
                return nextProps.history.push("/404");
              }
              for (
                let index = 0;
                index < nextProps.coach.coaches.length;
                index++
              ) {
                let coach = nextProps.coach.coaches[index];
                if (update.lesson.coach._id === coach._id) {
                  update.lesson.coach = coach;
                }
              }
            } else {
              console.log("redirecting to 404", 2);
              nextProps.history.push("/404");
            }
          }
        }
      } else {
        console.log("redirecting to 404", 4);
        nextProps.history.push("/404");
      }
      if (nextProps.auth) {
        update.auth = nextProps.auth;
      }
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push(`/login/?returnto=/lesson/${nextProps.id}`);
      }
      return update;
    } else {
      return null;
    }
  }
  uploadFile = (e) => {
    let { files } = e.target;
    const s3config = {
      bucketName: "equireel-public",
      dirName: `lessons/${this.state.lesson._id}` /* optional */,
      region: "eu-west-1",
      accessKeyId: "AKIAJPM53KOXH2ISAHLQ",
      secretAccessKey: "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",
    };
    const ReactS3Client = new S3(s3config);
    this.setState({
      ...this.state,
      isUploading: true,
    });
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (file) {
        let fileName = file.name.replace(/\.[^/.]+$/, "");
        ReactS3Client.uploadFile(file, fileName)
          .then((data) => {
            this.props.addAttachmentFile(
              { lesson: this.state.lesson._id, file: data.location },
              "user"
            );
            if (Number(index) === Number(files.length)) {
              this.setState({
                ...this.state,
                isUploading: false,
                uploadToggled: false,
              });
              this.forceUpdate();
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error(i18n("ue"));
            if (Number(index) === Number(files.length)) {
              this.setState({
                ...this.state,
                isUploading: false,
                uploadToggled: false,
              });
              this.forceUpdate();
            }
          });
      }
    }
    e.target.value = null;
  };
  deleteobject = (file) => {
    let confirmed = window.confirm(
      "Are you sure you want to delete this file from lesson?"
    );
    if (confirmed) {
      if (file.indexOf("coach-libraries") === -1) {
        const s3config = {
          bucketName: "equireel-public",
          region: "eu-west-1",
          accessKeyId: "AKIAJPM53KOXH2ISAHLQ",
          secretAccessKey: "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",
        };
        const ReactS3Client = new S3(s3config);
        ReactS3Client.deleteFile(
          file
            .split("https://equireel-public.s3-eu-west-1.amazonaws.com/")
            .pop()
        )
          .then((response) => {
            this.props.removeAttachmentFile(
              { lesson: this.state.lesson._id, file: file },
              "user"
            );
            this.forceUpdate();
          })
          .catch((err) => {
            console.error(err);
            toast.error(i18n("ue"));
          });
      } else {
        this.props.removeAttachmentFile(
          { lesson: this.state.lesson._id, file: file },
          "user"
        );
        this.forceUpdate();
      }
    }
    return false;
  };
  emailCoach = (e) => {
    e.preventDefault();
    alert(i18n("pleaseemailyourcoach"));
    window.location.href = `mailto:info@equireel.com?subject=${this.state.lesson.coach.fname} ${this.state.lesson.coach.lname} - PLEASE LEAVE THIS TEXT AS IT IS`;
    return false;
  };
  saveComments = (e) => {
    let val = document.querySelector("#commentsInput").value;
    this.props.changeLessonComments(this.state.lesson._id, val);
    this.setState({
      ...this.state,
      commentsToggled: false,
      lesson: {
        ...this.state.lesson,
        usercomments: val,
      },
    });
    this.props.getUserLessons();
  };
  render() {
    const { lesson } = this.state;
    const fileColumns = [
      {
        name: "Name",
        selector: "key",
        sortable: true,
        cell: (row) => (
          <a href={row} target="_blank" rel="noopener noreferrer">
            {row.split(`/`).pop()}
          </a>
        ),
      },
      {
        name: "Actions",
        cell: (row) => (
          <span
            style={{
              color: "var(--accent-color)",
              cursor: "pointer",
              marginLeft: "5px",
            }}
            onClick={() => {
              this.deleteobject(row);
              return false;
            }}
          >
            {i18n("delete")}
          </span>
        ),
      },
    ];
    return (
      <>
        {!this.state.is404 && this.state.auth.isAuthenticated && lesson && (
          <React.Fragment>
            <Helmet>
              <title>{i18n("lessontitle")}</title>
            </Helmet>
            {lesson && lesson.coach && (
              <Container className="lessonWrap">
                <Row>
                  <Col sm={12} className="titleSection">
                    <div className="backToLesson">
                      <Link to="/account/lessons/">
                        {i18n("backtoalllessons")}
                      </Link>
                    </div>
                    {lesson.status === "Completed" ||
                      (lesson.status === "Cancelled" && (
                        <div className="bookAgain">
                          <Link
                            to={`/coach/${lesson.coach._id}/`}
                            className="button"
                          >
                            {i18n("bookagain")}
                          </Link>
                        </div>
                      ))}
                  </Col>
                  <Col sm={12} md={8}>
                    <p>
                      {i18n("lessonline1").replace(
                        "[COACH_NAME]",
                        `${lesson.coach.fname} ${lesson.coach.lname}`
                      )}
                    </p>
                    <p>{i18n("lessonline2")}</p>
                    <p>
                      <a
                        href="https://zoom.us/download"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18n("downloadzoom")}
                      </a>
                    </p>
                    <p>{i18n("lessonline3")}</p>
                    <p>
                      {i18n("meetinglink")}:{" "}
                      {lesson.coach.zoom && lesson.coach.zoom.meetinglink && (
                        <a
                          href={lesson.coach.zoom.meetinglink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {lesson.coach.zoom.meetinglink}
                        </a>
                      )}
                      {!lesson.coach.zoom && i18n("notprovidedyet")}
                      {lesson.coach.zoom &&
                        !lesson.coach.zoom.meetinglink &&
                        i18n("notprovidedyet")}
                    </p>
                    <p>{i18n("lessonline4")}</p>
                    <p>{i18n("lessonline5")}</p>
                    <div className="fileSection">
                      <h2>{i18n("videoupload")}</h2>
                      <div className="actions">
                        {!this.state.uploadToggled && (
                          <div
                            className="addFile miniButton"
                            onClick={(e) =>
                              this.setState({
                                uploadToggled: true,
                              })
                            }
                          >
                            {i18n("uploadvideofile")}
                          </div>
                        )}
                        {this.state.uploadToggled && (
                          <>
                            <div
                              className="backToList miniButton"
                              onClick={(e) =>
                                this.setState({
                                  uploadToggled: false,
                                })
                              }
                            >
                              {i18n("backtofileslist")}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="optionsContent">
                        {this.state.uploadToggled && !this.state.isUploading && (
                          <div className="upload">
                            <h4>{i18n("uploadfile")}</h4>
                            <div>
                              <input
                                type="file"
                                name="file"
                                onChange={this.uploadFile}
                              />
                              <small>{i18n("uploadfileshere")}</small>
                            </div>
                          </div>
                        )}
                        {this.state.uploadToggled && this.state.isUploading && (
                          <div>
                            <small>{i18n("uploadingplzwait")}</small>
                          </div>
                        )}
                      </div>
                      {!this.state.uploadToggled && (
                        <DataTable
                          columns={fileColumns}
                          data={lesson.files}
                          theme="dark"
                        />
                      )}
                    </div>
                    <div className="commentsSection">
                      <h2>{i18n("tellusaboutyourself")}</h2>
                      {!this.state.commentsToggled && (
                        <>
                          <p>
                            {lesson.usercomments
                              ? lesson.usercomments
                              : i18n("nousercomment")}
                          </p>
                          <span
                            className="color-accent"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              this.setState({
                                ...this.state,
                                commentsToggled: !this.state.commentsToggled,
                              });
                            }}
                          >
                            {i18n("addedit")}
                          </span>
                        </>
                      )}
                      {this.state.commentsToggled && (
                        <>
                          <TextareaAutosize
                            name="userComments"
                            cols="30"
                            rows="10"
                            placeholder={i18n("usercommentsplaceholder")}
                            className="form-control textarea"
                            defaultValue={lesson.usercomments}
                            id="commentsInput"
                          />
                          <span
                            className="miniButton"
                            onClick={this.saveComments}
                          >
                            {i18n("save")}
                          </span>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="card alignedTable detailsTable">
                      <div className="cardTitle">{i18n("lessondetails")}</div>
                      <div className="cardContent infoList withPadding bg-secondary">
                        <ul className="contentList">
                          <li>
                            <div className="label">{i18n("lessonid")}</div>
                            <div className={`value`}>{lesson._id}</div>
                          </li>
                          <li>
                            <div className="label">{i18n("charged")}</div>
                            <div className="value">
                              <div className="price">
                                <span className="value">
                                  {lesson.charged.toFixed(2)}
                                </span>
                                <span className="currency">
                                  {lesson.currency}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="label">{i18n("slotdate")}</div>
                            <div className={`value`}>
                              <Moment
                                format="dddd DD/MM/YYYY"
                                tz="Europe/London"
                              >
                                {lesson.bookingslot}
                              </Moment>
                            </div>
                          </li>
                          <li>
                            <div className="label">{i18n("slottime")}</div>
                            <div className={`value`}>
                              <Moment format="hh:mm A" tz="Europe/London">
                                {lesson.bookingslot}
                              </Moment>
                            </div>
                          </li>
                          <li>
                            <div className="label">{i18n("bookedon")}</div>
                            <div className={`value`}>
                              <Moment format="DD/MM/YYYY" tz="Europe/London">
                                {lesson.date}
                              </Moment>
                            </div>
                          </li>
                          <li>
                            <div className="label">{i18n("status")}</div>
                            <div
                              className={`value ${
                                lesson.status === "In Progress"
                                  ? "text-info"
                                  : lesson.status === "Completed"
                                  ? "text-success"
                                  : lesson.status === "Awaiting Upload"
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                            >
                              {lesson.status}
                            </div>
                          </li>
                          <li>
                            <div className="label">{i18n("meetinglink")}</div>
                            <div className={`value`}>
                              {lesson.coach.zoom &&
                                lesson.coach.zoom.meetinglink && (
                                  <a
                                    href={lesson.coach.zoom.meetinglink}
                                    target="_blank"
                                    className="nostyle"
                                    rel="noopener noreferrer"
                                  >
                                    {i18n("clickhere")}
                                  </a>
                                )}
                              {lesson.coach.zoom &&
                                !lesson.coach.zoom.meetinglink &&
                                i18n("notprovidedyet")}
                              {!lesson.coach.zoom && i18n("notprovidedyet")}
                            </div>
                          </li>
                          <li>
                            <div className="label">{i18n("meetingid")}</div>
                            <div className={`value`}>
                              {lesson.coach.zoom
                                ? lesson.coach.zoom.meetingid
                                  ? lesson.coach.zoom.meetingid
                                  : i18n("notprovidedyet")
                                : i18n("notprovidedyet")}
                            </div>
                          </li>
                          <li>
                            <div className="label">
                              {i18n("meetingpassword")}
                            </div>
                            <div className={`value`}>
                              {lesson.coach.zoom
                                ? lesson.coach.zoom.meetingpassword
                                  ? lesson.coach.zoom.meetingpassword
                                  : i18n("notprovidedyet")
                                : i18n("notprovidedyet")}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p>
                      {i18n("uselessonidaspromo")}
                      {` ${format(
                        addDays(new Date(lesson.bookingslot), 10),
                        "MM/dd/yyyy",
                        { timeZone: "Europe/London" }
                      )}`}
                    </p>
                  </Col>
                </Row>
              </Container>
            )}
          </React.Fragment>
        )}
        {!lesson && (
          <Container className="fullScreenCenter vh70">
            <h1>{i18n("loading")}...</h1>
            <div className="spacer"></div>
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  coach: state.coach,
});

UserLesson.propTypes = {
  auth: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  getUserLessons: PropTypes.func.isRequired,
  addAttachmentFile: PropTypes.func.isRequired,
  removeAttachmentFile: PropTypes.func.isRequired,
  getCoaches: PropTypes.func.isRequired,
  changeLessonComments: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  setInfo,
  getUserLessons,
  addAttachmentFile,
  removeAttachmentFile,
  getCoaches,
  changeLessonComments,
})(withRouter(UserLesson));
