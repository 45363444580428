import {
  ADD_TO_CART,
  UPDATE_CART,
  SET_USER_ORDERS,
  SET_CURRENCY,
  INIT_CART,
} from "../types";

export const stripeKeys = {
  Pound: "pk_live_51KXNNUDX0g1Qc1AVEqihKrMDSc2Q1K4DkracnDrLosUi4aKynRRZVNlXMpYByDhlFS6ddj7MEG1DXdqQQRq7peMy00rXbRM8eZ",
  Euro: "pk_live_51AsxrILXhLJs7B9KVcSHaXhST0lmiKltuYKRRKXAluCKLVIPetkpSCkyof7Clchebm2mCBJQQn7U2lQ8HsziZ2SZ00nNRitnbx",
  USD: "pk_live_51AsxrILXhLJs7B9KVcSHaXhST0lmiKltuYKRRKXAluCKLVIPetkpSCkyof7Clchebm2mCBJQQn7U2lQ8HsziZ2SZ00nNRitnbx"
}

const initialState = {
  cart: [],
  userOrders: [],
  currency: "Pound",
  isInitialized: [],
  stripePublicKey: stripeKeys['Pound']
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cart: state.cart.concat(action.payload),
      };
    case UPDATE_CART:
      return {
        ...state,
        cart: action.payload,
      };
    case SET_USER_ORDERS:
      return {
        ...state,
        userOrders: action.payload,
      };
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
        stripePublicKey: stripeKeys[action.payload]
      };
    case INIT_CART:
      return {
        ...state,
        isInitialized: [...state.isInitialized, action.payload],
      };
    default:
      return state;
  }
};

export default cartReducer;