/* global i18n */
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import { Provider } from "react-redux";

import store from "./inc/store";
import { verifyThenSetUser } from "./inc/actions/authActions";
import { initCart, initCurrency } from "./inc/actions/cartActions";
import { ToastContainer, toast } from "react-toastify";

import ErrorBoundary from "./views/ErrorBoundary";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style/App.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./inc/localizationService";

import Header from "./views/inc/Header";
import Footer from "./views/inc/Footer";
import Home from "./views/Home";
import Cart from "./views/Cart";
import Account from "./views/Account";
import OrderDetails from "./views/OrderDetails";
import Login from "./views/authentication/Login";
import Register from "./views/authentication/Register";
import ForgotPassword from "./views/authentication/ForgotPassword";
import ResetPassword from "./views/authentication/ResetPassword";
import NotFound from "./views/NotFound";
import CoachListing from "./views/CoachListing";
import Coach from "./views/Coach";
import Lesson from "./views/Lesson";
import CreateTicket from "./views/CreateTicket";
import Ticket from "./views/Ticket";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      auth: {},
    };
  }
  componentDidMount() {
    store.dispatch(verifyThenSetUser());
    store.dispatch(initCart());
    store.dispatch(initCurrency());
    setTimeout(() => this.setState({ loading: false }), 1500);
  }

  changeLanguage = (e) => {
    e.preventDefault();
    window.changeLanguage(e.target.dataset.language);
    this.forceUpdate();
    toast.success(i18n("languageSwitched"));
    return false;
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return null;
    }
    const ShowAccount = () => {
      let { page } = useParams();

      return <Account page={page} />;
    };
    const ShowOrder = () => {
      let { id } = useParams();

      return <OrderDetails id={id} />;
    };
    const ShowCoach = () => {
      let { id } = useParams();

      return <Coach id={id} />;
    };
    const ShowLesson = () => {
      let { id } = useParams();

      return <Lesson id={id} />;
    };
    const ShowTicket = () => {
      let { id } = useParams();

      return <Ticket id={id} />;
    };
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Header />
            <main>
              <ErrorBoundary>
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/cart/" component={Cart} />
                  <Route path="/login" component={Login} />
                  <Route path="/register" component={Register} />
                  <Route path="/account/:page" children={<ShowAccount />} />
                  <Route path="/account/" children={<ShowAccount />} />
                  <Route path="/order/:id" children={<ShowOrder />} />
                  <Route path="/forgotpassword" component={ForgotPassword} />
                  <Route path="/resetpassword" component={ResetPassword} />
                  <Route path="/coaches" component={CoachListing} />
                  <Route path="/coach/:id" children={<ShowCoach />} />
                  <Route path="/lesson/:id" children={<ShowLesson />} />
                  <Route path="/ticket/create" exact component={CreateTicket} />
                  <Route path="/ticket/:id" children={<ShowTicket />} />
                  <Route component={NotFound} />
                </Switch>
              </ErrorBoundary>
            </main>
            <Footer changeLanguage={this.changeLanguage} />
          </div>
        </Router>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
      </Provider>
    );
  }
}

export default App;
