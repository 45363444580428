/* global i18n */
import { SET_USER_TICKETS, UNSET_INITIALIZED_SUPPORT } from "../types";
import { toast } from "react-toastify";
import axios from "axios";
import store from "./../../inc/store";
import Emitter from "./../../inc/emitter";

import io from "socket.io-client";

export const receiveMessage = (msg) => (dispatch) => {
  Emitter.emit("CHAT_MSG", msg);
};

let socket;

if (!socket) {
  // socket = io(":8080");
  socket = io("https://equireel.co.uk:8085");
  socket.on("msg", (msg) => {
    store.dispatch(receiveMessage(msg));
  });
}

export const getUserTickets = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "support/ofuser")
    .then((res) => {
      dispatch({
        type: SET_USER_TICKETS,
        payload: res.data,
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = i18n("ue");
      }
      toast.error(e);
    });
};

export const removeInialized = (reducer, item) => (dispatch) => {
  switch (reducer) {
    case "support":
      dispatch({
        type: UNSET_INITIALIZED_SUPPORT,
        payload: item,
      });
      break;

    default:
      break;
  }
};
export const createTicket = (ticket, history) => (dispatch) => {
  dispatch(removeInialized("support", "userTickets"));
  axios
    .post(process.env.REACT_APP_API_PATH + "support/create", ticket)
    .then((res) => {
      dispatch(getUserTickets());
      toast.success(i18n("ticketreceived"));
      history.push(`/ticket/${res.data._id}`);
    })
    .catch((err) => {
      toast.error(i18n("unexpectedErrorContactSupport"));
      console.error(err.response ? err.response : err);
    });
};

export const sendMessage = (ticket, message) => (dispatch) => {
  try {
    socket.emit("msg", { ticket, message });
  } catch (err) {
    console.error(err);
  }
};

export const changeTicketStatus = (ticket, status) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "support/changestatus", {
      ticket,
      status,
    })
    .then((res) => {
      toast.success(i18n("ticketstatusupdated"));
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};
