import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import HttpsRedirect from "react-https-redirect";
import * as serviceWorker from "./serviceWorker";

// If service workers are supported, and one isn't already registered
// if ("serviceWorker" in navigator && !navigator.serviceWorker.controller) {
//   navigator.serviceWorker.register("/OneSignalSDKWorker.js");
// }

if (process && process.env && process.env.REACT_APP_ENV === "production") {
    ReactDOM.render(
        <HttpsRedirect>
            <App />
        </HttpsRedirect>,
        document.getElementById("root")
    );
} else {
    ReactDOM.render(<App />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
