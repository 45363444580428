export const SET_USER = "SET_USER";
export const UNSET_USER = "UNSET_USER";
export const SET_INFO = "SET_INFO";
export const GET_RECORDS = "GET_RECORDS";
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const SET_USER_ORDERS = "SET_USER_ORDERS";
export const SET_CURRENCY = "SET_CURRENCY";
export const INIT_CART = "INIT_CART";
export const SET_COACHES = "SET_COACHES";
export const SET_COACH = "SET_COACH";
export const SET_USER_LESSONS = "SET_USER_LESSONS";
export const SET_COACH_LESSONS = "SET_COACH_LESSONS";
export const SET_USER_TICKETS = "SET_USER_TICKETS";
export const UNSET_INITIALIZED_SUPPORT = "UNSET_INITIALIZED_SUPPORT";
