/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Emitter from "./../../inc/emitter";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: {}
    };
    this.copyrightRef = React.createRef();
    Emitter.on("LANG_UPDATE", (lang) =>
    {
      if (this.copyrightRef && this.copyrightRef.current && this.copyrightRef.current !== null) {
        this.copyrightRef.current.innerHTML = i18n("copyrightText");
      }
    }
    );
  }
  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.auth) {
        update.auth = nextProps.auth;
      }
      return update;
    } else {
      return null;
    }
  }
  render() {
    return (
      <footer>
        {this.state.auth.isAuthenticated && !this.state.auth.profileComplete && (
          <div className="alertCompleteProfile">
            <div className="label">{i18n("pleasecompleteprofile")}</div>
            <div className="cta">
              <Link
                to="/account/profile/"
                className="button bg-text color-accent"
                style={{ backgroundColor: "var(--text-color) !important" }}
              >
                {i18n("clickhere")}
              </Link>
            </div>
          </div>
        )}
        <Container>
          <Row>
            <Col>
              <p style={{ opacity: 1 }}>
                <a
                  href="#/"
                  data-language="en"
                  onClick={this.props.changeLanguage}
                >
                  English
                </a>{" "}
                -{" "}
                <a
                  href="#/"
                  data-language="fr"
                  onClick={this.props.changeLanguage}
                >
                  French
                </a>
              </p>
              <p className="color-text">
                &copy; {new Date().getFullYear()} -
                {" "}<span ref={this.copyrightRef}>{i18n("copyrightText")}</span>{" "}
                <a
                  href="https://linktr.ee/rabisnaqvi"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Rabis Naqvi"
                >
                  Rabis Naqvi
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(Footer);
