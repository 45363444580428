/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Select from "react-virtualized-select";
import TextareaAutosize from "react-textarea-autosize";

import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";

import { checkMinLength } from "../inc/Validation";
import { getUserOrders } from "../inc/actions/cartActions";
import { getUserLessons } from "../inc/actions/bookingActions";
import { setInfo } from "../inc/actions/infoActions";
import { createTicket } from "../inc/actions/supportActions";

class CreateTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: {},
      orderOptions: [],
      lessonOptions: [],
    };
    this.props.setInfo({
      for: "currentPage",
      data: "createTicket",
    });
    this.props.getUserOrders();
    this.props.getUserLessons();
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.cart) {
        if (nextProps.cart.userOrders) {
          let userOrders = nextProps.cart.userOrders;
          let orderOptions = [];
          for (let index = 0; index < userOrders.length; index++) {
            const element = userOrders[index];
            let date = new Date(element.date);
            let localDateString = date.toLocaleDateString(undefined, {
              day: "numeric",
              month: "short",
              year: "numeric",
            });

            let localTimeString = date.toLocaleTimeString(undefined, {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            });
            orderOptions.push({
              label: `${localDateString} ${localTimeString} - ${element._id}`,
              value: element._id,
            });
          }
          update.orderOptions = orderOptions;
        }
      }
      if (nextProps.coach) {
        if (nextProps.coach.userLessons) {
          let userLessons = nextProps.coach.userLessons;
          let lessonOptions = [];
          for (let index = 0; index < userLessons.length; index++) {
            const element = userLessons[index];
            let date = new Date(element.date);
            let localDateString = date.toLocaleDateString(undefined, {
              day: "numeric",
              month: "short",
              year: "numeric",
            });

            let localTimeString = date.toLocaleTimeString(undefined, {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            });
            lessonOptions.push({
              label: `${localDateString} ${localTimeString} - ${element._id}`,
              value: element._id,
            });
          }
          update.lessonOptions = lessonOptions;
        }
      }
      if (!nextProps.auth.isAuthenticated) {
        return nextProps.history.push(`/login/?returnto=/ticket/create`);
      }
      if (nextProps.auth.user && nextProps.auth.user.type === "coach") {
        return nextProps.history.push(`/login/?returnto=/account/`);
      }
      return update;
    } else {
      return null;
    }
  }
  changeHandler = (e, iselect) => {
    if (!iselect) {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      if (e.value) {
        if (e.name === "type") {
          this.setState({ [e.name]: e.value, element: "" });
        } else {
          this.setState({ [e.name]: e.value });
        }
      } else {
        this.setState({ [e.name]: "" });
      }
    }
  };
  validate = () => {
    var errorMsg = "";
    const { type, element, title, description } = this.state;
    if (!type) {
      errorMsg = i18n("tickettyperequired");
      toast.error(errorMsg);
      return false;
    }
    if (type === "orders" && !element) {
      errorMsg = i18n("orderrequired");
      toast.error(errorMsg);
      return false;
    }
    if (type === "lessons" && !element) {
      errorMsg = i18n("lessonrequired");
      toast.error(errorMsg);
      return false;
    }
    if (!title || !checkMinLength(title, 5)) {
      errorMsg = i18n("lengthtickettitle");
      toast.error(errorMsg);
      return false;
    }
    if (!description || !checkMinLength(description, 10)) {
      errorMsg = i18n("lengthticketdescription");
      toast.error(errorMsg);
      return false;
    }
    return true;
  };
  CreateTicket = (e) => {
    e.preventDefault();
    let { type, element, title, description } = this.state;
    if (this.validate()) {
      const Ticket = {
        type,
        element,
        title,
        description,
      };

      this.props.createTicket(Ticket, this.props.history);
    } else {
      return false;
    }
  };
  render() {
    return (
      <>
        <Helmet>
          <title>{i18n("createTicketTitle")}</title>
        </Helmet>
        <Container className="createTicketWrap">
          <Row>
            <Col>
              <div className="pageTitle">
                <h2>{i18n("createTicketTitle")}</h2>
                <div className="cta">
                  <Link className="button" to="/account/support/">
                    {i18n("backtoaccount")}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <div className="pageContentWrap">
            <div className="pageContent card">
              <div className="content cardContent withPadding">
                <form action="post" onSubmit={this.CreateTicket}>
                  <Row>
                    <Col sm={12} md={2}>
                      <label htmlFor="type">{i18n("tickettype")}</label>
                    </Col>
                    <Col sm={12} md={10}>
                      <Select
                        options={[
                          {
                            label: i18n("general"),
                            value: "general",
                          },
                          {
                            label: i18n("orders"),
                            value: "orders",
                          },
                          {
                            label: i18n("lessonscoaches"),
                            value: "lessons",
                          },
                          {
                            label: i18n("account"),
                            value: "account",
                          },
                          {
                            label: i18n("technical"),
                            value: "technical",
                          },
                        ]}
                        onChange={(type) =>
                          this.changeHandler(
                            {
                              name: "type",
                              value: type ? type.value : null,
                            },
                            true
                          )
                        }
                        value={this.state.type}
                        placeholder={i18n("tickettype")}
                      />
                    </Col>
                  </Row>
                  {this.state.type === "orders" && (
                    <Row>
                      <Col sm={12} md={2}>
                        <label htmlFor="order">{i18n("selectorder")}</label>
                      </Col>
                      <Col sm={12} md={10}>
                        <Select
                          options={
                            this.state.orderOptions
                              ? this.state.orderOptions
                              : null
                          }
                          onChange={(order) =>
                            this.changeHandler(
                              {
                                name: "element",
                                value: order ? order.value : null,
                              },
                              true
                            )
                          }
                          value={this.state.element}
                          placeholder={i18n("selectorder")}
                        />
                      </Col>
                    </Row>
                  )}
                  {this.state.type === "lessons" && (
                    <Row>
                      <Col sm={12} md={2}>
                        <label htmlFor="lesson">{i18n("selectlesson")}</label>
                      </Col>
                      <Col sm={12} md={10}>
                        <Select
                          options={
                            this.state.lessonOptions
                              ? this.state.lessonOptions
                              : null
                          }
                          onChange={(lesson) =>
                            this.changeHandler(
                              {
                                name: "element",
                                value: lesson ? lesson.value : null,
                              },
                              true
                            )
                          }
                          value={this.state.element}
                          placeholder={i18n("selectlesson")}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm={12} md={2}>
                      <label htmlFor="title">{i18n("tickettitle")}</label>
                    </Col>
                    <Col sm={12} md={10}>
                      <input
                        onChange={this.changeHandler}
                        name="title"
                        className="form-control title"
                        type="text"
                        placeholder={i18n("tickettitleplaceholder")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={2}>
                      <label htmlFor="title">{i18n("ticketdescription")}</label>
                    </Col>
                    <Col sm={12} md={10}>
                      <TextareaAutosize
                        name="description"
                        cols="30"
                        rows="50"
                        placeholder={i18n("ticketdescriptionplaceholder")}
                        className="form-control textarea"
                        onBlur={(e) =>
                          this.setState({
                            ...this.state,
                            description: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="submit"
                        value={i18n("createticket")}
                        className="button"
                      />
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  cart: state.cart,
  coach: state.coach,
});

CreateTicket.propTypes = {
  auth: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  getUserOrders: PropTypes.func.isRequired,
  getUserLessons: PropTypes.func.isRequired,
  createTicket: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  setInfo,
  getUserOrders,
  createTicket,
  getUserLessons,
})(withRouter(CreateTicket));
