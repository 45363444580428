/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import "moment-timezone";

import { setInfo } from "../inc/actions/infoActions";
import { getUserOrders } from "../inc/actions/cartActions";

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is404: false,
      order: {},
      got: false,
    };
    this.props.setInfo({
      for: "currentPage",
      data: "account",
    });
    this.props.getUserOrders();
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.id) {
        if (nextProps.cart) {
          if (nextProps.cart.isInitialized.indexOf("userOrders") !== -1) {
            if (nextProps.cart.userOrders) {
              update.userOrders = nextProps.cart.userOrders;
              let found = false;
              for (let index = 0; index < update.userOrders.length; index++) {
                let userOrder = update.userOrders[index];
                if (userOrder._id === nextProps.id) {
                  update.id = nextProps.id;
                  update.is404 = false;
                  found = true;
                  update.order = userOrder;
                  update.got = true;
                }
              }
              if (!found && update.got) {
                console.log("redirecting to 404", 1);
                nextProps.history.push("/404");
              }
            } else {
              console.log("redirecting to 404", 2);
              nextProps.history.push("/404");
            }
          }
        }
      } else {
        console.log("redirecting to 404", 4);
        nextProps.history.push("/404");
      }
      if (nextProps.auth) {
        update.auth = nextProps.auth;
      }
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push(`/login/?returnto=/order/${nextProps.id}`);
      }
      if (nextProps.auth.user && nextProps.auth.user.type === "coach") {
        return nextProps.history.push(`/account/`);
      }
      return update;
    } else {
      return null;
    }
  }
  render() {
    return (
      <>
        {!this.state.is404 &&
          this.state.auth &&
          this.state.auth.isAuthenticated &&
          this.state.order && (
            <React.Fragment>
              <Helmet>
                <title>{i18n("orderdetailstitle")}</title>
              </Helmet>
              {this.state.order && this.state.order.cart && (
                <Container className="orderWrap">
                  <Row>
                    <Col sm={12}>
                      <div className="backToOrder">
                        <Link to="/account/orders/">
                          {i18n("backtoallorders")}
                        </Link>
                      </div>
                    </Col>
                    <Col sm={12} md={8}>
                      <div className="card cart-table">
                        <div className="cardTitle">{i18n("orderitems")}</div>
                        <div className="cardContent bg-secondary">
                          <table className="table table-responsive color-text">
                            <tbody>
                              {this.state.order.cart.map((item) => (
                                <tr key={this.state.order.cart.indexOf(item)}>
                                  <td>
                                    <ul>
                                      {Object.entries(item).map((val, key) => [
                                        val[0] !== "Price" &&
                                          val[0] !== "Horse" && (
                                            <li key={key}>
                                              <span className="label">
                                                {val[0] + ":"}
                                              </span>
                                              <span className="value">
                                                {val[1] ? val[1] : "N/A"}
                                              </span>
                                            </li>
                                          ),
                                      ])}
                                    </ul>
                                  </td>
                                  <td>
                                    <span className="price">
                                      <span className="value">
                                        {item.Price}
                                      </span>
                                      <span className="currency">
                                        {this.state.order.currency}
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <br />
                      <p>{i18n("orderdetailsparagraph")}</p>
                    </Col>
                    <Col sm={12} md={4}>
                      <div className="card alignedTable">
                        <div className="cardTitle">{i18n("orderdetails")}</div>
                        <div className="cardContent infoList withPadding bg-secondary">
                          <ul className="contentList">
                            <li>
                              <div className="label">{i18n("charged")}</div>
                              <div className="value">
                                <div className="price">
                                  <span className="value">
                                    {this.state.order.charged.toFixed(2)}
                                  </span>
                                  <span className="currency">
                                    {this.state.order.currency}
                                  </span>
                                </div>
                              </div>
                            </li>
                            {this.state.order.promo && (
                              <li>
                                <div className="label">
                                  {i18n("promodiscount")}
                                </div>
                                <div className="value">
                                  {this.state.order.promo.code}
                                </div>
                              </li>
                            )}
                            <li>
                              <div className="label">{i18n("date")}</div>
                              <div className={`value`}>
                                <Moment format="DD/MM/YYYY" tz="Europe/London">
                                  {this.state.order.date}
                                </Moment>
                              </div>
                            </li>
                            <li>
                              <div className="label">{i18n("status")}</div>
                              <div
                                className={`value ${
                                  this.state.order.status === "Processing"
                                    ? "text-warning"
                                    : this.state.order.status === "Completed"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {this.state.order.status}
                              </div>
                            </li>
                            <li>
                              <div className="label">
                                {i18n("shareconsent")}
                              </div>
                              <div className={`value`}>
                                {this.state.order.shareconsent
                                  ? i18n("yes")
                                  : i18n("no")}
                              </div>
                            </li>
                            <li>
                              <div className="label">
                                {i18n("askincurfaults")}
                              </div>
                              <div className={`value`}>
                                {i18n(this.state.order.includefaults)}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </React.Fragment>
          )}
        {!this.state.order && (
          <Container className="fullScreenCenter vh70">
            <h1>{i18n("loading")}...</h1>
            <div className="spacer"></div>
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  cart: state.cart,
});

OrderDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  getUserOrders: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setInfo, getUserOrders })(
  withRouter(OrderDetails)
);
