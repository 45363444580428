/* global i18n */
import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import "moment-timezone";
import { toast } from "react-toastify";
import addressjson from "countrycitystatejson";
import validator from "validator";
import Select from "react-virtualized-select";
import Faq from "react-faq-component";
import qs from "qs";

import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";

import { checkMinLength } from "../../inc/Validation";
import { getUserOrders, doCheckout } from "../../inc/actions/cartActions";
import { getUserTickets } from "../../inc/actions/supportActions";
import { getUserLessons } from "../../inc/actions/bookingActions";
import {
  changePassword,
  editUser,
  unsetUser,
} from "../../inc/actions/authActions";

class CustomerAccount extends React.Component {
  constructor(props) {
    super(props);
    let countries = addressjson.getCountries();
    let countryOptions = [];
    for (let index = 0; index < countries.length; index++) {
      const element = countries[index];
      countryOptions.push({
        label: element.name,
        value: element.name,
      });
    }
    this.state = {
      orders: [],
      userOrders: [],
      userLessons: [],
      userTickets: [],
      fname: "",
      lname: "",
      address1: "",
      address2: "",
      country: "",
      state: "",
      city: "",
      postalcode: "",
      phone: "",
      countryOptions: countryOptions,
      auth: {},
      initialized: false,
    };
    this.props.getUserOrders();
    this.props.getUserLessons();
    this.props.getUserTickets();
    if (this.props.page && this.props.page === "orders") {
      let success = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      }).success;
      if (success === "true") {
        this.props.doCheckout(this.props.history);
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.cart) {
        if (nextProps.cart.userOrders) {
          update.userOrders = nextProps.cart.userOrders;
        }
      }
      if (nextProps.coach) {
        if (nextProps.coach.userLessons) {
          update.userLessons = nextProps.coach.userLessons;
        }
      }
      if (nextProps.support) {
        if (nextProps.support.userTickets) {
          update.userTickets = nextProps.support.userTickets;
        }
      }
      if (nextProps.auth) {
        update.auth = nextProps.auth;
        if (update.auth.user.fname && prevProps.initialized === false) {
          update.initialized = true;
          update.fname = nextProps.auth.user.fname;
          update.lname = nextProps.auth.user.lname;
          update.address1 = nextProps.auth.user.address1;
          update.address2 = nextProps.auth.user.address2;
          update.country = nextProps.auth.user.country;
          update.state = nextProps.auth.user.state;
          update.city = nextProps.auth.user.city;
          update.postalcode = nextProps.auth.user.postalcode;
          update.phone = nextProps.auth.user.phone;
        }
      }
      if (nextProps.page) {
        update.page = nextProps.page;
      }
      if (!nextProps.auth.isAuthenticated) {
        nextProps.history.push(`/login/?returnto=/account/${nextProps.page}`);
      }
      return update;
    } else {
      return null;
    }
  }
  logoutUser = (e) => {
    e.preventDefault();
    this.props.unsetUser("/", this.props.history);
    return false;
  };
  changeHandler = (e, iselect) => {
    if (!iselect) {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      if (e.value) {
        this.setState({ [e.name]: e.value });
      } else {
        this.setState({ [e.name]: "" });
      }
    }
  };
  submitPasschangeHandler = (e) => {
    e.preventDefault();
    let errorMsg = i18n("ue");
    let { currentPassword, newPassword } = this.state;
    if (!currentPassword || !checkMinLength(currentPassword, 6)) {
      errorMsg = i18n("invalidCurrentPassword");
      toast.error(errorMsg);
      return false;
    }
    if (!newPassword || !checkMinLength(newPassword, 6)) {
      errorMsg = i18n("passwordatleast");
      toast.error(errorMsg);
      return false;
    }

    this.props.changePassword({ currentPassword, newPassword });
    return false;
  };
  validate = () => {
    var errorMsg = "";
    const {
      fname,
      lname,
      // address1,
      country,
      // state,
      city,
      postalcode,
      phone,
    } = this.state;
    if (!fname || !checkMinLength(fname, 3)) {
      errorMsg = i18n("lengthfname");
      toast.error(errorMsg);
      return false;
    }
    if (!lname || !checkMinLength(lname, 3)) {
      errorMsg = i18n("lengthlname");
      toast.error(errorMsg);
      return false;
    }
    if (!country) {
      errorMsg = i18n("requiredcountry");
      toast.error(errorMsg);
      return false;
    }
    if (!city) {
      errorMsg = i18n("requiredcity");
      toast.error(errorMsg);
      return false;
    }
    if (!postalcode) {
      errorMsg = i18n("requiredpostalcode");
      toast.error(errorMsg);
      return false;
    }
    if (!phone) {
      errorMsg = i18n("requiredphonenumber");
      toast.error(errorMsg);
      return false;
    }
    // verify phone
    if (!validator.isMobilePhone(phone)) {
      errorMsg = i18n("validphoneformat");
      errorMsg +=
        " (123) 456-7890, (123)456-7890, 123-456-7890, 123.456.7890, 1234567890, +31636363634, 075-63546725";
      toast.error(errorMsg, { autoClose: false });
      return false;
    }
    return true;
  };
  EditProfileSubmitHandler = (e) => {
    e.preventDefault();
    let {
      fname,
      lname,
      address1,
      address2,
      country,
      state,
      city,
      postalcode,
      phone,
    } = this.state;
    if (this.validate()) {
      console.log("form validated");

      const User = {
        id: this.state.auth.user._id,
        fname,
        lname,
        address1,
        address2,
        country,
        state,
        city,
        postalcode,
        phone,
      };

      this.props.editUser(User, this.props.history);
    } else {
      return false;
    }
  };
  render() {
    const faqdata = {
      rows: [
        {
          title: "Lorem ipsum dolor sit amet,",
          content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat, 
                ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus. 
                In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae. 
                Fusce sed commodo purus, at tempus turpis.`,
        },
        {
          title: "Nunc maximus, magna at ultricies elementum",
          content:
            "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam, vitae convallis ex tortor sed dolor.",
        },
        {
          title: "Curabitur laoreet, mauris vel blandit fringilla",
          content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem. 
              Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam. 
              Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat. 
              Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
        },
        {
          title: "What is the package version",
          content: "v1.0.0",
        },
      ],
    };
    const faqstyles = {
      bgColor: "transparent",
      // titleTextColor: "blue",
      rowTitleColor: "var(--accent-color)",
      rowContentColor: "#fff",
      // arrowColor: "red",
    };
    return (
      <>
        {!this.state.is404 && this.state.auth.isAuthenticated && (
          <React.Fragment>
            <Helmet>
              <title>{i18n("accountTitle")}</title>
            </Helmet>
            <Container className="accountWrap">
              <Row>
                <Col sm={12} md={3}>
                  <div className="accountNavWrap">
                    <ul className="accountNav">
                      <li>
                        <Link
                          to="/account/dashboard/"
                          className={
                            this.props.page === "dashboard" ? "active" : ""
                          }
                        >
                          {i18n("dashboard")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/account/orders/"
                          className={
                            this.props.page === "orders" ? "active" : ""
                          }
                        >
                          {i18n("orders")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/account/lessons/"
                          className={
                            this.props.page === "lessons" ? "active" : ""
                          }
                        >
                          {i18n("lessons")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/account/profile/"
                          className={
                            this.props.page === "profile" ? "active" : ""
                          }
                        >
                          {i18n("editAccount")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/account/password/"
                          className={
                            this.props.page === "password" ? "active" : ""
                          }
                        >
                          {i18n("changePassword")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/account/support/"
                          className={
                            this.props.page === "support" ? "active" : ""
                          }
                        >
                          {i18n("support")}
                        </Link>
                      </li>
                      <li onClick={this.logoutUser}>
                        <Link to="/">{i18n("logout")}</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col sm={12} md={9}>
                  <div className="pageContentWrap">
                    {this.props.page === "dashboard" && (
                      <div className="pageContent dashboardContent card">
                        <h2 className="contentTitle cardTitle">
                          {i18n("dashboard")}
                        </h2>
                        <div className="content cardContent withPadding">
                          <p>
                            <span className="boldfamily">
                              {i18n("hi")} {this.state.auth.user.name}!
                            </span>{" "}
                            {i18n("canManageAccountHere")}{" "}
                            <Link to="/account/orders/">
                              {i18n("seeYourOrders")}
                            </Link>{" "}
                            {i18n("andMore")}.
                          </p>
                        </div>
                      </div>
                    )}
                    {this.props.page === "orders" && (
                      <div className="pageContent ordersContent card">
                        <h2
                          className="contentTitle cardTitle"
                          style={{ margin: "0" }}
                        >
                          {i18n("orders")}
                        </h2>
                        <div
                          className="content cardContent bg-primary"
                          style={{ overflow: "scroll" }}
                        >
                          {this.state.userOrders &&
                            this.state.userOrders.length > 0 && (
                              <>
                                <Table>
                                  <thead>
                                    <tr>
                                      <th>{i18n("date")}</th>
                                      <th>{i18n("status")}</th>
                                      <th>{i18n("charged")}</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.userOrders.map((order) => [
                                      <tr key={order._id}>
                                        <td style={{ minWidth: "160px" }}>
                                          <Moment
                                            format="dddd MMMM Do, YYYY hh:mm A"
                                            date={order.date}
                                            tz="Europe/London"
                                          />
                                        </td>
                                        <td>{order.status}</td>
                                        <td>
                                          <span className="price">
                                            <span className="value">
                                              {order.charged.toFixed(2)}
                                              <span className="currency">
                                                {order.currency}
                                              </span>
                                            </span>
                                          </span>
                                        </td>
                                        <td style={{ minWidth: "130px" }}>
                                          <Link
                                            to={`/order/${order._id}`}
                                            className="miniButton"
                                            style={{ color: "#fff" }}
                                          >
                                            {i18n("vieworder")}
                                          </Link>
                                        </td>
                                      </tr>,
                                    ])}
                                  </tbody>
                                </Table>
                              </>
                            )}
                          {this.state.userOrders.length === 0 && (
                            <p style={{ padding: "20px" }}>
                              {i18n("orderwillshowuphere")}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    {this.props.page === "lessons" && (
                      <div className="pageContent lessonsContent card">
                        <h2
                          className="contentTitle cardTitle"
                          style={{ margin: "0" }}
                        >
                          {i18n("lessons")}
                        </h2>
                        <div
                          className="content cardContent bg-primary"
                          style={{ overflow: "scroll" }}
                        >
                          {this.state.userLessons &&
                            this.state.userLessons.length > 0 && (
                              <Table striped>
                                <thead>
                                  <tr>
                                    <th>{i18n("coach")}</th>
                                    <th>{i18n("date")}</th>
                                    <th>{i18n("status")}</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.userLessons.map((lesson) => [
                                    <tr key={lesson._id}>
                                      <td>{`${lesson.coach.fname} ${lesson.coach.lname}`}</td>
                                      <td>
                                        <Moment
                                          format="dddd MMMM Do, YYYY hh:mm A"
                                          date={lesson.bookingslot}
                                          tz="Europe/London"
                                        />
                                      </td>
                                      <td>
                                        {lesson.status === "progress"
                                          ? `${i18n("inprogress")}`
                                          : lesson.status}
                                      </td>
                                      <td style={{ minWidth: "170px" }}>
                                        <Link
                                          to={`/lesson/${lesson._id}`}
                                          style={{
                                            background: "var(--accent-color)",
                                            color: "#fff",
                                            padding: "3px 10px",
                                            borderRadius: "50px",
                                          }}
                                        >
                                          {i18n("viewupload")}
                                        </Link>
                                      </td>
                                    </tr>,
                                  ])}
                                </tbody>
                              </Table>
                            )}
                          {this.state.userLessons.length === 0 && (
                            <p style={{ padding: "20px" }}>
                              {i18n("lessonswillshowuphere")}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    {this.props.page === "profile" && (
                      <div className="pageContent passwordContent card">
                        <h2 className="contentTitle cardTitle">
                          {i18n("editAccount")}
                        </h2>
                        <div className="content cardContent withPadding">
                          <form
                            action="post"
                            onSubmit={this.EditProfileSubmitHandler}
                          >
                            <input
                              onChange={this.changeHandler}
                              name="fname"
                              className="form-control fname"
                              type="text"
                              placeholder={i18n("fname")}
                              value={this.state.fname}
                              style={{ width: "50%", display: "inline-block" }}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="lname"
                              className="form-control lname"
                              type="text"
                              placeholder={i18n("lname")}
                              value={this.state.lname}
                              style={{ width: "50%", display: "inline-block" }}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="address1"
                              className="form-control address1"
                              type="text"
                              placeholder={i18n("address1")}
                              value={this.state.address1}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="address2"
                              className="form-control address2"
                              type="text"
                              placeholder={i18n("address2")}
                              value={this.state.address2}
                            />
                            <Select
                              options={
                                this.state.countryOptions
                                  ? this.state.countryOptions
                                  : null
                              }
                              onChange={(country) =>
                                this.changeHandler(
                                  {
                                    name: "country",
                                    value: country ? country.value : null,
                                  },
                                  true
                                )
                              }
                              value={this.state.country}
                              placeholder={i18n("selectcountry")}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="state"
                              className="form-control state"
                              type="text"
                              placeholder={i18n("stateprovince")}
                              value={this.state.state}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="city"
                              className="form-control city"
                              type="text"
                              placeholder={i18n("city")}
                              value={this.state.city}
                              style={{ width: "50%", display: "inline-block" }}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="postalcode"
                              className="form-control postalcode"
                              type="text"
                              placeholder={i18n("postalcode")}
                              value={this.state.postalcode}
                              style={{
                                width: "50%",
                                display: "inline-block",
                                borderLeft: "0",
                              }}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="phone"
                              className="form-control phone"
                              type="text"
                              placeholder={i18n("phonenumber")}
                              value={this.state.phone}
                            />
                            <input
                              className="button bg-accent"
                              type="submit"
                              value={i18n("save")}
                            />
                          </form>
                        </div>
                      </div>
                    )}
                    {this.props.page === "password" && (
                      <div className="pageContent passwordContent card">
                        <h2 className="contentTitle cardTitle">
                          {i18n("changePassword")}
                        </h2>
                        <div className="content cardContent withPadding">
                          <form
                            action="post"
                            onSubmit={this.submitPasschangeHandler}
                          >
                            <input
                              onChange={this.changeHandler}
                              name="currentPassword"
                              className="form-control currentPassword"
                              type="password"
                              placeholder={i18n("currentPassword")}
                            />
                            <input
                              onChange={this.changeHandler}
                              name="newPassword"
                              className="form-control newPassword"
                              type="password"
                              placeholder={i18n("newpassword")}
                            />
                            <input
                              className="button bg-accent"
                              type="submit"
                              value={i18n("changePassword")}
                            />
                          </form>
                        </div>
                      </div>
                    )}
                    {this.props.page === "faq" && (
                      <div className="pageContent faqContent card">
                        <h2 className="contentTitle cardTitle">
                          {i18n("faq")}
                        </h2>
                        <div className="content cardContent withPadding">
                          <Faq data={faqdata} styles={faqstyles} />
                        </div>
                      </div>
                    )}
                    {this.props.page === "support" && (
                      <>
                        <div className="pageContent supportContent card">
                          <h2
                            className="contentTitle cardTitle expand"
                            style={{ margin: "0" }}
                          >
                            {i18n("support")}
                            <div className="cardAction">
                              <Link to="/ticket/create" className="button">
                                {i18n("createsupportticket")}
                              </Link>
                            </div>
                          </h2>
                          <div className="content cardContent bg-primary">
                            {this.state.userTickets &&
                              this.state.userTickets.length > 0 && (
                                <ul className="contentList">
                                  {this.state.userTickets.map((ticket) => [
                                    <li key={ticket._id}>
                                      <Link to={`/ticket/${ticket._id}`}>
                                        <div className="content">
                                          <div className="title">
                                            {ticket.title}
                                            <span className="meta">
                                              <span className="date">
                                                <Moment
                                                  fromNow
                                                  locale={window.i18nlang}
                                                  tz="Europe/London"
                                                >
                                                  {ticket.date}
                                                </Moment>
                                              </span>
                                            </span>
                                          </div>
                                          <div className="content">
                                            <p className="key">
                                              <small>
                                                <span>{i18n("id")}:</span>{" "}
                                                <span>{ticket._id}</span>
                                              </small>
                                              <small>
                                                <span> {i18n("status")}:</span>{" "}
                                                <span
                                                  className={
                                                    ticket.status ===
                                                    "In Progress"
                                                      ? "text-info"
                                                      : ticket.status ===
                                                        "Resolved"
                                                      ? "text-success"
                                                      : "text-danger"
                                                  }
                                                >
                                                  {ticket.status}
                                                </span>
                                              </small>
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </li>,
                                  ])}
                                </ul>
                              )}
                            {this.state.userTickets.length === 0 && (
                              <p style={{ padding: "20px" }}>
                                {i18n("ticketswillshowuphere")}
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        )}
        {this.state.is404 && (
          <>
            <h1 className="text-center">404 :(</h1>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  cart: state.cart,
  coach: state.coach,
  support: state.support,
});

CustomerAccount.propTypes = {
  auth: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  getUserOrders: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  getUserLessons: PropTypes.func.isRequired,
  unsetUser: PropTypes.func.isRequired,
  getUserTickets: PropTypes.func.isRequired,
  doCheckout: PropTypes.func,
};

export default connect(mapStateToProps, {
  getUserOrders,
  changePassword,
  editUser,
  getUserLessons,
  unsetUser,
  getUserTickets,
  doCheckout,
})(withRouter(CustomerAccount));
