/* global i18n */
import { SET_COACHES, SET_USER_LESSONS, SET_COACH_LESSONS } from "../types";
import { toast } from "react-toastify";
import axios from "axios";

export const getCoaches = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "auth/coaches")
    .then((res) => {
      dispatch({
        type: SET_COACHES,
        payload: res.data,
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = i18n("ue");
      }
      toast.error(e);
    });
};

export const doBooking = (booking, history) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "booking/create", booking)
    .then((res) => {
      toast.success(i18n("emailBookingReceived"));
      history.push(`/lesson/${res.data._id}`);
    })
    .catch((err) => {
      let e = err;
      if (e.response) {
        e = e.response.data.error;
      }
      if (!e) {
        e = i18n("ue");
      } else {
        e = i18n(e);
      }
      if (e) {
        toast.error(e);
      } else {
        toast.error(i18n("unexpectedErrorContactSupport"));
      }
      console.error(err);
    });
};

export const getUserLessons = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "booking/ofuser")
    .then((res) => {
      dispatch({
        type: SET_USER_LESSONS,
        payload: res.data,
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = i18n("ue");
      }
      toast.error(e);
    });
};

export const getCoachLessons = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "booking/ofcoach")
    .then((res) => {
      dispatch({
        type: SET_COACH_LESSONS,
        payload: res.data,
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = i18n("ue");
      }
      toast.error(e);
    });
};

export const changeLessonStatus = (lesson, status) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "booking/changestatus", {
      lesson,
      status,
    })
    .then((res) => {
      toast.success(i18n("lessonstatusupdated"));
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};
export const changeLessonComments = (lesson, comment) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "booking/changecomment", {
      lesson,
      comment,
    })
    .then((res) => {
      toast.success(i18n("updated"));
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = "undefined error";
      }
      toast.error(e);
    });
};

export const addAttachmentFile = (data, calledFrom) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "booking/addattachmentfile", data)
    .then((res) => {
      if (calledFrom === "coach") {
        dispatch(getCoachLessons());
      } else {
        dispatch(getUserLessons());
      }
      toast.success(i18n("fileadded"));
    })
    .catch((err) => {
      let e = err.response
        ? i18n(err.response.data.error)
          ? i18n(err.response.data.error)
          : err.response.data.error
        : false;
      if (!e) {
        e = i18n("ue");
        console.error(err);
      }
      toast.error(e);
    });
};
export const removeAttachmentFile = (data, calledFrom) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "booking/removeattachmentfile", data)
    .then((res) => {
      if (calledFrom === "coach") {
        dispatch(getCoachLessons());
      } else {
        dispatch(getUserLessons());
      }
      toast.success(i18n("filedeleted"));
    })
    .catch((err) => {
      let e = err.response
        ? i18n(err.response.data.error)
          ? i18n(err.response.data.error)
          : err.response.data.error
        : false;
      if (!e) {
        e = i18n("ue");
        console.error(err);
      }
      toast.error(e);
    });
};
