/* global i18n */
import {
  ADD_TO_CART,
  UPDATE_CART,
  SET_USER_ORDERS,
  SET_CURRENCY,
  INIT_CART,
} from "../types";
import { toast } from "react-toastify";
import axios from "axios";
import { detectUserRegion, detectUserCity, EU_PoundCountryCapitals } from "../localizationService";

export const addToCart =
  (itemData, redirectTo = false, history = false) =>
  (dispatch) => {
    let cart = localStorage.getItem("cart");
    cart = cart ? JSON.parse(cart) : [];
    let updatedCart = [...cart, itemData];
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    dispatch({
      type: ADD_TO_CART,
      payload: itemData,
    });

    if (redirectTo === "cart") {
      if (history) {
        history.push("/cart/");
      }
    }
    toast.success(i18n("addedToCart"));
  };

export const removeFromCart = (key) => (dispatch) => {
  let cart = localStorage.getItem("cart");
  cart = cart ? JSON.parse(cart) : [];
  let updatedCart = [...cart];
  updatedCart.splice(key, 1);
  localStorage.setItem("cart", JSON.stringify(updatedCart));
  dispatch({
    type: UPDATE_CART,
    payload: updatedCart,
  });
  toast.success(i18n("cartItemRemoved"));
};

export const initCart = () => (dispatch) => {
  let cart = localStorage.getItem("cart");
  cart = cart ? JSON.parse(cart) : [];
  dispatch({
    type: ADD_TO_CART,
    payload: cart,
  });
  dispatch({
    type: INIT_CART,
    payload: "cart",
  });
};

export const createCheckoutSession = (cart) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "orders/createcheckoutsession", {
      cart,
    })
    .then(async (res) => {
      const session = await res.json();
      return session;
    });
};

export const doCheckout = (history) => (dispatch) => {
  dispatch({
    type: UPDATE_CART,
    payload: [],
  });
  localStorage.removeItem("cart");
  toast.success(i18n("emailOrderReceived"));
  history.push(`/account/orders`);
};

export const getUserOrders = () => (dispatch) => {
  axios
    .get(process.env.REACT_APP_API_PATH + "orders/ofuser")
    .then((res) => {
      dispatch({
        type: SET_USER_ORDERS,
        payload: res.data,
      });
      dispatch({
        type: INIT_CART,
        payload: "userOrders",
      });
    })
    .catch((err) => {
      let e = err;
      if (!e) {
        e = i18n("ue");
      }
      toast.error(e);
    });
};

export const verifyPromo = (promo) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(process.env.REACT_APP_API_PATH + "promos/byid", { promo })
      .then((res) => {
        if (res.data) {
          if (res.data._id) {
            resolve(res.data);
          }
        } else {
          reject(false);
        }
      })
      .catch((err) => {
        let e = err;
        if (e.response) {
          e = e.response.data;
        }
        if (!e) {
          e = i18n("couldnotverifypromo");
        } else {
          e = i18n(e);
        }
        reject(e);
      });
  });
};

export const switchCurrency = (switchto) => (dispatch) => {
  dispatch({
    type: SET_CURRENCY,
    payload: switchto,
  });
};

export const initCurrency = () => (dispatch) => {
  const Region = detectUserRegion();
  const City = detectUserCity();
  let currency;
  switch (Region) {
    case "Europe":
      currency = "Euro";
      break;
    case "America":
      currency = "USD";
      break;
    default:
      currency = "Pound";
      break;
  }
  if(currency === "Euro") {
    EU_PoundCountryCapitals.forEach(capital => {
      if(City === capital) currency = "Pound";
    });
  }
  dispatch(switchCurrency(currency));
};
