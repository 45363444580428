/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import { isEqual, isBefore } from "date-fns";
import StripeCheckout from "react-stripe-checkout";
import Moment from "react-moment";
import queryString from "query-string";
import "moment-timezone";

import { setInfo } from "../inc/actions/infoActions";
import { getCoaches, doBooking } from "../inc/actions/bookingActions";

class Coach extends React.Component {
  constructor(props) {
    super(props);
    // slot
    let slot = queryString.parse(this.props.location.search).slot;
    slot = slot ? new Date(slot * 1000) : false;
    console.log(slot);
    this.state = {
      is404: false,
      coach: {},
      got: false,
      selectedSlot: slot,
      currency: "Pound",
    };
    this.props.setInfo({
      for: "currentPage",
      data: "coach",
    });
    this.props.getCoaches();
    this.myRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.id) {
        if (nextProps.coach) {
          if (nextProps.coach.initialized.indexOf("coaches") !== -1) {
            if (nextProps.coach.coaches) {
              update.coaches = nextProps.coach.coaches;
              let found = false;
              for (let index = 0; index < update.coaches.length; index++) {
                let coach = update.coaches[index];
                if (coach._id === nextProps.id) {
                  coach.availability = coach.availability.filter(function (
                    date
                  ) {
                    if (isBefore(new Date(date), new Date())) {
                      return false;
                    } else {
                      return true;
                    }
                  });
                  coach.availability.sort(function (a, b) {
                    return new Date(a) - new Date(b);
                  });
                  update.is404 = false;
                  found = true;
                  update.coach = coach;
                  update.got = true;
                }
              }
              if (!found && update.got) {
                console.log("redirecting to 404", 1);
                nextProps.history.push("/404");
              }
            } else {
              console.log("redirecting to 404", 2);
              nextProps.history.push("/404");
            }
          }
        }
      } else {
        console.log("redirecting to 404", 4);
        nextProps.history.push("/404");
      }
      if (nextProps.auth) {
        update.auth = nextProps.auth;
      }
      if (nextProps.auth.isAuthenticated) {
        if (nextProps.auth.user.type === "coach") {
          nextProps.history.push(`/account`);
        }
      }
      if (nextProps.auth.user && nextProps.auth.user.type === "coach") {
        return nextProps.history.push(`/account/`);
      }
      return update;
    } else {
      return null;
    }
  }
  onToken = (token, addresses) => {
    let { coach, selectedSlot, currency } = this.state;
    let stripe = JSON.stringify([token, addresses]);
    const Booking = {
      coach,
      bookingslot: selectedSlot,
      charged: coach.fee,
      currency,
      stripe,
    };
    this.props.doBooking(Booking, this.props.history);
  };
  selectSlot = (slot) => {
    this.setState({
      ...this.state,
      selectedSlot: new Date(slot),
    });
    window.scrollTo(0, this.myRef.current.offsetTop);
  };
  render() {
    const coach = this.state.coach;
    return (
      <>
        {!this.state.is404 && this.state.coach && (
          <React.Fragment>
            <Helmet>
              <title>{i18n("coachtitle")}</title>
            </Helmet>
            {this.state.coach && (
              <Container className="coachWrap">
                <Row>
                  <Col>
                    <div className="image">
                      <img
                        src={`https://equireel-public.s3.amazonaws.com/coach-dp/${coach.dp}`}
                        className="img-fluid"
                        alt={`${coach.fname} ${coach.lname} - Equireel`}
                      />
                    </div>
                    <div className="name">
                      {`${coach.fname} ${coach.lname}`}
                    </div>
                    <div className="bio">{coach.bio}</div>
                    <Row>
                      <Col sm={12} md={3}></Col>
                      <Col sm={12}>
                        <div className="availabilitySelection">
                          <div className="label">{i18n("availableslots")}</div>
                          {coach.availability && coach.availability.length > 0 && (
                            <ul className="slotsListing">
                              {coach.availability.map((slot) => [
                                <li
                                  className={`slotItem ${
                                    isEqual(
                                      this.state.selectedSlot,
                                      new Date(slot)
                                    )
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={(e) => this.selectSlot(slot)}
                                  key={String(slot)}
                                >
                                  <Moment
                                    format={"dddd MMMM Do, YYYY hh:mm A"}
                                    date={new Date(slot)}
                                    tz="Europe/London"
                                  />
                                </li>,
                              ])}
                            </ul>
                          )}
                        </div>
                      </Col>
                      <Col sm={12} md={3}></Col>
                    </Row>
                    <div className="selectedDate">
                      <span className="label">{i18n("selectedslot")}: </span>
                      {this.state.selectedSlot ? (
                        <Moment
                          format={`dddd MMMM Do, YYYY hh:mm A`}
                          date={this.state.selectedSlot}
                          tz="Europe/London"
                        />
                      ) : (
                        i18n("notselected")
                      )}
                    </div>
                    <div className="price">
                      <span className="label">{i18n("fee")}: </span>
                      <span className="value">
                        {Number(coach.fee).toFixed(2)}
                      </span>
                      <span className="currency">{this.state.currency}</span>
                    </div>
                    <div className="bookingButton" ref={this.myRef}>
                      {this.state.auth &&
                        this.state.auth.isAuthenticated &&
                        this.state.auth.profileComplete &&
                        this.state.selectedSlot && (
                          <>
                            <StripeCheckout
                              amount={Number((coach.fee * 100).toFixed(2))}
                              locale={window.i18nlang}
                              email={this.state.auth.user.email}
                              name={
                                this.state.auth.user.fname +
                                " " +
                                this.state.auth.user.lname
                              }
                              currency={
                                this.state.currency === "Euro" ? "EUR" : "GBP"
                              }
                              stripeKey="pk_live_OuThOmowrOUQt71hUfaN5DPg" //Live
                              // stripeKey="pk_test_oqugh6aQdUkujM3q2V0Sqslr" // dev
                              token={this.onToken}
                              ComponentClass="div"
                            >
                              <button className="button checkoutLink">
                                {i18n("book")}
                              </button>
                            </StripeCheckout>
                          </>
                        )}
                      {this.state.auth &&
                        this.state.auth.isAuthenticated &&
                        this.state.auth.profileComplete &&
                        !this.state.selectedSlot && <>{i18n("selectaslot")}</>}
                      {this.state.auth && !this.state.auth.isAuthenticated && (
                        <>
                          <Link
                            className="button checkoutLink loginLink"
                            to={`/login?returnto=/coach/${coach._id}?slot=${
                              this.state.selectedSlot
                                ? this.state.selectedSlot.getTime() / 1000
                                : ""
                            }`}
                          >
                            {i18n("logintobook")}
                          </Link>
                        </>
                      )}
                      {this.state.auth &&
                        this.state.auth.isAuthenticated &&
                        !this.state.auth.profileComplete && (
                          <>
                            <Link
                              className="button checkoutLink editAccountLink"
                              to="/account/profile"
                            >
                              {i18n("editAccount")}
                            </Link>
                            <p style={{ textAlign: "center", marginBottom: 0 }}>
                              <small>{i18n("pleasecompleteprofile")}</small>
                            </p>
                          </>
                        )}
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </React.Fragment>
        )}
        {!this.state.coach && (
          <Container className="fullScreenCenter vh70">
            <h1>{i18n("loading")}...</h1>
            <div className="spacer"></div>
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  coach: state.coach,
});

Coach.propTypes = {
  auth: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  getCoaches: PropTypes.func.isRequired,
  doBooking: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setInfo, getCoaches, doBooking })(
  withRouter(Coach)
);
