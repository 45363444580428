export function checkMinLength(e = null, min) {
  if (e.length < min) {
    return false;
  } else {
    return true;
  }
}

export function checkEmail(e = null) {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(e);
}

export function maxLength(files, max) {
  if (files.length > max) {
    return false;
  }
  return true;
}

export function isInvalidMimeType(files) {
  let err = false;
  const types = ['application/x-bsh', 'application/x-sh', 'application/x-shar', 'text/x-script.sh']
  for (var x = 0; x < files.length; x++) {
    for (var e = 0; e < types.length; e++) {
      if (types[e] === files[x].type) {
        err = files[x].type;
      }
    }
  };
  if (err) {
    return err;
  }
  return false;

}

export function checkFileSize(files) {
  let size = 52428800
  let err = false;
  for (var x = 0; x < files.length; x++) {
    if (files[x].size > size) {
      err = files[x].name;
    }
  };
  if (err !== '') {
    return err
  }
  return false;
}
