import {
  SET_COACH,
  SET_COACHES,
  SET_USER_LESSONS,
  SET_COACH_LESSONS,
} from "../types";

const initialState = {
  coaches: [],
  coach: {},
  userLessons: [],
  coachLessons: [],
  initialized: [],
};

const coachReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COACHES:
      return {
        ...state,
        coaches: action.payload,
        initialized: [...state.initialized, "coaches"],
      };
    case SET_COACH:
      return {
        ...state,
        coach: action.payload,
      };
    case SET_USER_LESSONS:
      return {
        ...state,
        userLessons: action.payload,
        initialized: [...state.initialized, "userLessons"],
      };
    case SET_COACH_LESSONS:
      return {
        ...state,
        coachLessons: action.payload,
        initialized: [...state.initialized, "coachLessons"],
      };
    default:
      return state;
  }
};

export default coachReducer;