/* global i18n */
import { SET_USER, UNSET_USER } from "../types";
import axios from "axios";
import setAuthHeader from "../setAuthHeader";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

export const registerUser = (userData, history, returnto = "/") => (
  dispatch
) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "auth/register", userData)
    .then((res) => {
      dispatch(setUser(res.data.token, "registeration"));
      toast.success(i18n("idcreated"));
      history.push(returnto);
    })
    .catch((err) => {
      let e = err.response
        ? i18n(err.response.data.error)
          ? i18n(err.response.data.error)
          : err.response.data.error
        : false;
      if (!e) {
        e = i18n("ue");
        console.log(err);
      }
      toast.error(e);
    });
};

export const editUser = (userData, history, returnto = "/account/profile/") => (
  dispatch
) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "auth/edituser", userData)
    .then((res) => {
      dispatch(setUser(res.data.token, "edition"));
      toast.success(i18n("idedited"));
      history.push(returnto);
    })
    .catch((err) => {
      let e = err.response
        ? i18n(err.response.data.error)
          ? i18n(err.response.data.error)
          : err.response.data.error
        : false;
      if (!e) {
        e = i18n("ue");
        console.log(err);
      }
      toast.error(e);
    });
};

export const loginUser = (userData, history, returnto) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "auth/login", userData)
    .then((res) => {
      dispatch(setUser(res.data.token, "login"));
      toast.success(i18n("loggedin"));
      history.push(returnto);
    })
    .catch((err) => {
      let e =
        typeof err.response !== "undefined" ? err.response.data.error : err;
      if (e === "TOBEUPDATED") {
        toast.info(i18n("askresetpassword"), { autoClose: false });
        toast.info(i18n("askresetpasswordexplanation"), { autoClose: false });
        return history.push("/forgotpassword");
      }
      if (!e) {
        e = i18n("ue");
      }
      toast.error(e);
    });
};

export const setUser = (userData, calledFrom) => (dispatch) => {
  localStorage.setItem("jwtToken", userData);
  setAuthHeader(userData);
  let decoded;
  try {
    decoded = jwt_decode(userData);
  } catch (err) {
    decoded = "";
    if (
      calledFrom === "login" ||
      calledFrom === "registeration" ||
      calledFrom === "edition"
    ) {
      toast.error(i18n("unexpectedErrorContactSupport"));
      return false;
    }
  }
  dispatch({
    type: SET_USER,
    payload: decoded,
  });
};

export const verifyThenSetUser = () => (dispatch) => {
  const token = localStorage.getItem("jwtToken");
  if (token) {
    let decoded;
    try {
      decoded = jwt_decode(token);
    } catch (err) {
      decoded = "";
      console.log("error decoding token", err, token);
      toast.error(i18n("unexpectedErrorContactSupport"));
    }
    if (!decoded._id) {
      return false;
    }
    axios
      .post(process.env.REACT_APP_API_PATH + "auth/verifyUserData", decoded)
      .then((res) => {
        if (res.data.token) {
          dispatch(setUser(res.data.token, "verifyAndSetUser"));
          return "true";
        } else {
          toast.warning(i18n("loginAgain"));
          return false;
        }
      })
      .catch((err) => {
        toast.warning(i18n("loginAgain"));
        console.log(err);
        return false;
      });
  } else {
    return false;
  }
};

export const unsetUser = (redirect, history) => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthHeader();
  dispatch({
    type: UNSET_USER,
    payload: false,
  });
  toast.success(i18n("logoutSuccess"));
  history.push("/");
};

export const changePassword = (passData) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "auth/changepassword", passData)
    .then((res) => {
      toast.success(i18n("passwordChanged"));
    })
    .catch((err) => {
      let e = err.response.data.error;
      if (!e) {
        e = i18n("ue");
      }
      toast.error(e);
    });
};

export const createResetRequest = (email) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "auth/createresetrequest", {
      email: email,
    })
    .then((res) => {
      toast.success(i18n("resetrequestcreatedmessage"), { autoClose: false });
    })
    .catch((err) => {
      let e = err.response.data.error;
      if (!e) {
        e = i18n("ue");
      }
      e = i18n(e) ? i18n(e) : e;
      toast.error(e);
    });
};

export const DoResetPassword = (password, confirmpassword, token, history) => (
  dispatch
) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "auth/resetpassword", {
      password,
      confirmpassword,
      token,
    })
    .then((res) => {
      toast.success(i18n("resetpasssuccess"));
      history.push("/login");
    })
    .catch((err) => {
      let e = err.response.data.error;
      if (!e) {
        e = i18n("ue");
      }
      e = i18n(e) ? i18n(e) : e;
      toast.error(e);
    });
};

export const updateAvailability = (availabilityData) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "auth/changeavailability", {
      availability: availabilityData,
    })
    .then((res) => {
      dispatch(setUser(res.data.token, "verifyAndSetUser"));
      toast.success(i18n("availabilityChanged"));
    })
    .catch((err) => {
      let e = err.response.data.error;
      if (!e) {
        e = i18n("ue");
      }
      toast.error(e);
    });
};

export const editCoach = (
  userData,
  history,
  returnto = "/account/profile/"
) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "auth/editcoach", userData)
    .then((res) => {
      dispatch(setUser(res.data.token, "edition"));
      toast.success(i18n("idedited"));
      history.push(returnto);
    })
    .catch((err) => {
      let e = err.response
        ? i18n(err.response.data.error)
          ? i18n(err.response.data.error)
          : err.response.data.error
        : false;
      if (!e) {
        e = i18n("ue");
        console.log(err);
      }
      toast.error(e);
    });
};

export const addAttachmentFile = (file) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "auth/addattachmentfile", file)
    .then((res) => {
      dispatch(setUser(res.data.token, "edition"));
    })
    .catch((err) => {
      let e = err.response
        ? i18n(err.response.data.error)
          ? i18n(err.response.data.error)
          : err.response.data.error
        : false;
      if (!e) {
        e = i18n("ue");
        console.error(err);
      }
      toast.error(e);
    });
};
export const removeAttachmentFile = (file) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_API_PATH + "auth/removeattachmentfile", file)
    .then((res) => {
      dispatch(setUser(res.data.token, "edition"));
      toast.success(i18n("filedeleted"));
    })
    .catch((err) => {
      let e = err.response
        ? i18n(err.response.data.error)
          ? i18n(err.response.data.error)
          : err.response.data.error
        : false;
      if (!e) {
        e = i18n("ue");
        console.error(err);
      }
      toast.error(e);
    });
};
