const fr = {
  account: "Compte",
  login: "S'identifier",
  signup: "S'inscrire",
  logout: "Se deconnecter",
  idcreated: "Votre identifiant Equireel a été créé",
  ue: "erreur non définie",
  loggedin: "Connecté",
  unexpectedErrorContactSupport:
    "erreur inattendue. veuillez contacter le support.",
  loginAgain: "Veuillez vous reconnecter",
  logoutSuccess: "À bientôt :)",
  passwordChanged: "Mot de passe changé",
  addedToCart: "Ajouté au panier",
  cartItemRemoved: "Élément du panier supprimé",
  emailOrderReceived:
    "Votre commande a été reçue. Vous recevrez un e-mail de confirmation de notre part.",
  currencySwitched: "Changement de devise",
  errorSwitchCurrency: "Impossible de changer la devise",
  errorInvalidEmailPass: "E-mail / mot de passe invalide",
  loginToEquireel: "Connectez-vous à Equireel",
  loginTo: "Se connecter a",
  registerat: "S'inscrire a",
  email: "Email",
  password: "Mot de passe",
  name: "Nom",
  submit: "Soumettre",
  dontHaveId: "Vous n'avez pas d'ID Equireel?",
  alreadyHaveId: "Vous avez déjà un identifiant Equireel?",
  nameatleast: "Le nom doit comporter au moins 4 caractères",
  passwordatleast: "Le mot de passe doit contenir au moins 6 caractères",
  invalidEmail: "Email invalide",
  registerID: "Enregistrer un identifiant Equireel",
  copyrightText: `Tous les droits sont réservés. Fabriqué avec amour par`,
  invalidCurrentPassword: "Mot de passe actuel invalide",
  accountTitle: "Gérer le compte - Equireel",
  dashboard: "Tableau de bord",
  orders: "Ordres",
  changePassword: "Changer le mot de passe",
  hi: "salut",
  canManageAccountHere: "Vous pouvez gérer votre compte ici.",
  seeYourOrders: "Voir vos commandes",
  andMore: "et plus",
  orderkey: "Clé de commande",
  orderwillshowuphere: "Vos commandes apparaîtront ici",
  currentPassword: "Mot de passe actuel",
  newpassword: "nouveau mot de passe",
  errorEnterPromoFirst: "Veuillez d'abord entrer une Promo",
  promoapplied: "Promo appliquée",
  couldnotverifypromo: "Impossible de vérifier la promotion",
  logintoapplypromo: "Veuillez vous connecter pour appliquer la promo",
  cartpagetitle: "Chariot - Equireel",
  cart: "Chariot",
  checkout: "Check-out",
  remove: "Retirer",
  subtotal: "Subtotal",
  promocode: "Code promo",
  promofieldplaceholder: "Entrez Promo et appuyez sur retour",
  promodiscount: "Remise promotionnelle",
  total: "Total",
  logintocheckout: "Connectez-vous a la caisse",
  andapplypromocodes: "et appliquer des codes promotionnels",
  noitemsincart: "Aucun article trouve dans le panier",
  filltoplaceorder:
    "Remplissez le formulaire ci-dessous pour commander votre video",
  selectcountry: "Choisissez le pays",
  selectevent: "Sélectionnez un événement",
  selecthorse: "Sélectionnez un cheval",
  selectvideotype: "Sélectionnez le type de vidéo",
  price: "Prix",
  addtocart: "Ajouter au chariot",
  orderdetailstitle: "détails de la commande - Equireel",
  backtoallorders: "Retour à toutes les commandes",
  orderitems: "Items commandes",
  orderdetails: "details de la commande",
  charged: "coûter",
  date: "Date",
  status: "Statut",
  pagenotfound: "Page non trouvée",
  languageSwitched: "Langue commutée",
  losttheway: "Peut-etre que vous avez perdu le chemin?...",
  gobackhome: "Rentrer a la maison",
  forgotyourpassword: "Mot de passe oublié?",
  resetPassword: "reinitialiser le mot de passe",
  nouserwiththisemail: "Aucun utilisateur avec cet e-mail trouvé",
  resetrequestcreatedmessage:
    "La demande de réinitialisation du mot de passe a été créée. Veuillez vérifier votre e-mail pour le lien de réinitialisation. Assurez-vous de vérifier le dossier spam.",
  passwordsnotmatching: "Les mots de passe ne correspondent pas",
  confirmpassword: "Confirmez le mot de passe",
  invaliddataprovided: "Données non valides fournies",
  resetpasssuccess:
    "Le mot de passe a été réinitialisé. Veuillez vous connecter maintenant.",
  askresetpassword:
    "Veuillez réinitialiser le mot de passe de votre compte pour vous connecter.",
  askresetpasswordexplanation:
    "Nous avons apporté quelques modifications à notre système, cette action est nécessaire pour assurer la sécurité de votre compte. Ce n'est qu'une action unique.",
  selectseason: "Sélectionnez la saison",
  loading: "Chargement",
  invalidPassword: "Mot de passe incorrect",
  userwithemailexists: "un utilisateur avec cet e-mail existe déjà",
  promoremoved: "Promo supprimée",
  askshareconsent:
    "Vidéo publique (décochez si vous souhaitez garder la vidéo privée)",
  shareconsent: "Partager le consentement",
  yes: "Oui",
  no: "Non",
  home: "Accueil",
  fname: "Prénom",
  lname: "Nom de famille",
  lengthfname: "Le prénom doit contenir au moins 3 caractères",
  lengthlname: "Le nom de famille doit contenir au moins 3 caractères",
  address1: "Adresse de rue",
  address2: "Adresse postale, ligne 2",
  city: "Ville",
  stateprovince: "État / Province",
  postalcode: "code postal",
  lengthaddress: "L'adresse doit contenir au moins 2 caractères",
  requiredcountry: "Le pays est obligatoire",
  requiredstate: "L'état est requis",
  requiredcity: "La ville est obligatoire",
  requiredpostalcode: "Le code postal est requis",
  phonenumber: "Numéro de téléphone",
  requiredphonenumber: "Le numéro de téléphone est requis",
  validphoneformat: "Veuillez saisir un numéro de téléphone au format suivant:",
  editAccount: "Modifier le compte",
  save: "sauvegarder",
  idedited: "Compte modifié avec succès",
  pleasecompleteprofile:
    "Veuillez compléter votre profil afin de passer des commandes",
  clickhere: "Cliquez ici", // ----------------------------------------
  support: "Soutien",
  ticketswillshowuphere: "Vos tickets d'assistance s'afficheront ici",
  createsupportticket: "Creer un ticket d'assistance",
  ticketdetailstitle: "Détails du ticket",
  backtoaccount: "Revenir au compte",
  createTicketTitle: "Créer un ticket d'assistance",
  selecttickettype: "Sélectionnez le type de ticket",
  order: "Commande",
  technical: "Technique",
  tickettype: "Type de billet",
  selectorder: "Sélectionnez votre commande",
  tickettitle: "Titre du ticket",
  tickettitleplaceholder: "Expliquez brièvement votre problème",
  ticketdescription: "Description du ticket",
  ticketdescriptionplaceholder: "Expliquez le problème en détail",
  createticket: "Créer un ticket",
  lengthtickettitle: "Le titre du ticket doit contenir au moins 5 caractères",
  lengthticketdescription:
    "La description du ticket doit contenir au moins 10 caractères",
  tickettyperequired: "Un type de ticket est requis",
  orderrequired: "La commande est requise",
  ticketreceived: "Votre ticket d'assistance a été reçu",
  calendar: "Calendrier",
  coach: "Entraîneur",
  editcalendar: "Modifier le calendrier",
  lessons: "Cours",
  viewlessons: "Voir les lecons",
  attachmentlibrary: "Bibliotheque de pieces jointes",
  selectavailabilityrange: "Sélectionnez votre plage de disponibilité",
  availabilityChanged: "Votre disponibilité a été mise à jour",
  bio: "Bio",
  lengthbio: "La biographie doit comprendre au moins 10 caractères",
  coaches: "Entraineurs",
  ourcoaches: "Nos coachs",
  days: "Journées",
  day: "journée",
  lessonavailablewithin: "Leçon disponible en",
  today: "Aujourd'hui",
  lessonavailabletoday: "Leçon disponible aujourd'hui",
  nextavailableslot: "Emplacement suivant disponible:",
  lessonfee: "Frais de leçon",
  booknow: "Reserve maintenant",
  coachtitle: "Entraîneur - Equireel",
  availableslots: "Dates disponibles",
  selectedslot: "Date sélectionnée",
  notselected: "Non séléctionné",
  fee: "Frais",
  book: "Livre",
  logintobook: "Connectez-vous pour reserver",
  emailBookingReceived:
    "Votre réservation a été reçue. Vous recevrez un e-mail de confirmation de notre part.",
  selectaslot: "Veuillez sélectionner un emplacement de réservation",
  lessonswillshowuphere: "Vos leçons apparaîtront ici",
  lesson: "Leçon",
  inprogress: "En cours",
  backtoalllessons: "Retour aux leçons",
  lessontitle: "Leçon - Equireel",
  emailcoach: "Email Coach",
  emailcustomer: "Envoyer un e-mail au client",
  user: "Utilisateur",
  lessondetails: "Details de la lecon",
  lessonstatusupdated: "Statut de la leçon mis à jour",
  seeYourLessons: "Voir vos leçons",
  xcvideos: "Videos XC",
  noslotsyet:
    "Les emplacements créés apparaîtront ici. Vous n'avez encore créé aucun emplacement",
  createdslots: "Emplacements disponibles:",
  addslot: "Ajouter un emplacement",
  selectslotfirst: "Veuillez d'abord sélectionner l'emplacement",
  slotalreadyselected: "Emplacement déjà ajouté",
  delete: "Supprimer",
  dontforgettosavechanges: "N'oubliez pas d'enregistrer les modifications",
  downloadzoom: "Télécharger Zoom",
  notprovidedyet: "Pas encore fourni",
  addlink: "Ajouter un lien",
  editlink: "Modifier le lien",
  zoommeetinglink: "Zoom Lien de réunion",
  pleaseenterlink: "Veuillez saisir le lien",
  lessonlinkupdated: "Lien vers la leçon mis à jour",
  slotdate: "Date de la fente",
  slottime: "Temps de fente",
  bookedon: "Réservé sur",
  change: "Changement",
  upload: "Telecharger",
  list: "liste",
  uploadfileshere: "Téléchargez des fichiers ici",
  fileuploaded: "Fichier téléchargé",
  uploadingplzwait:
    "Le ou les fichiers sont en cours de téléchargement. Veuillez patienter et garder la page ouverte ...",
  filedeleted: "Fichier supprimé",
  files: "Des dossiers",
  addfile: "Ajouter le fichier",
  fromlibrary: "De la bibliothèque",
  backtofileslist: "Retour à la liste des fichiers",
  addtolesson: "Ajouter à la leçon",
  fileadded: "Fichier ajouté",
  addfilefromlibrary: "Ajouter un fichier de la bibliotheque",
  uploadfile: "Televerser un fichier",
  bookagain: "Réservez à nouveau",
  view: "Vue",
  uselessonidaspromo:
    "Utilisez votre ID de leçon comme code promotionnel pour obtenir 50% de réduction lors de l'achat de XC Video chez nous! Valable jusqu'au",
  lessonid: "id de leçon",
  paymenterror:
    "Une erreur s'est produite lors du traitement de votre paiement",
  videoupload: "Telechargement video",
  meetinglink: "Zoom Lien de réunion",
  meetingid: "Zoom ID de réunion",
  meetingpassword: "Zoom Mot de passe de réunion",
  coaching: "encadrement",
  uploadvideofile: "Télécharger une vidéo / un fichier ",
  pleaseemailyourcoach:
    "Veuillez envoyer un e-mail à votre coach uniquement si vous rencontrez des problèmes techniques ou avez besoin d'aide. Tous les autres messages et informations doivent être ajoutés à votre boîte de message",
  viewupload: "Afficher et télécharger",
  viewlibrary: "Voir la bibliotheque",
  faq: "FAQ's",
  lessonline1: "Merci d'avoir réservé un cours avec [COACH_NAME].",
  lessonline2:
    "Vous devrez télécharger le zoom afin de participer à la leçon, lien de téléchargement disponible ci-dessous.",
  lessonline3:
    "Le lien de la réunion sera mis à jour le jour de la leçon, il vous suffit de cliquer sur ce lien 10 minutes avant la leçon et la réunion de zoom s'ouvrira dans l'application de zoom que vous avez téléchargée.",
  lessonline4:
    "Veuillez télécharger un maximum de 10 minutes d'images ci-dessous au plus tard la veille de la leçon.",
  lessonline5:
    "Veuillez vous assurer de numéroter et de nommer vos fichiers vidéo de manière appropriée avant de les télécharger.",
  tellusaboutyourself: "Parlez nous de vous",
  usercommentsplaceholder: "Dites au coach sur quoi vous aimeriez travailler",
  addedit: "Ajouter / modifier",
  updated: "Mise à jour",
  comments: "commentaires",
  nousercomment:
    "À quel niveau êtes-vous actuellement en compétition et y a-t-il quelque chose de spécifique sur lequel vous souhaitez des conseils?",
  lessonscoaches: "Leçons / entraîneurs",
  selectlesson: "Sélectionnez la leçon",
  lessonrequired: "Une leçon est requise",
  id: "Identifiant",
  ticketdetails: "Details du ticket",
  messages: "messages",
  orderdetailsparagraph:
    "Remarque: le traitement des vidéos XC peut prendre jusqu'à 7 jours.Une fois terminée, votre vidéo sera envoyée par e-mail.",
  vieworder: "Voir l'ordre",
  writeyourmessagehere: "Ecrivez votre message ici",
  send: "Envoyer",
  writeamessagetostartconversation:
    "Écrivez un message pour démarrer la conversation",
  ticketstatusupdated: "État du ticket mis à jour",
  general: "Général",
  promolimitreached: "limite de promotion atteinte",
  promoexpired: "Promo expirée",
  filladditionaldata: "Veuillez remplir tout les champs",
  askincurfaults: "Inclure les défauts (le cas échéant)",
  null: "",
  ordervideos: "Commander des videos",
  sthwentwrong: "Quelque chose s'est mal passé, désolé :(",
  reloadthepage: "Veuillez recharger la page.",
  or: "Ou"
};

export default fr;