/* global i18n */
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";

import { setInfo } from "../inc/actions/infoActions";
import UserLesson from "./Lessons/UserLesson";
import CoachLesson from "./Lessons/CoachLesson";

class Lesson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: {},
      id: props.id,
    };
    this.props.setInfo({
      for: "currentPage",
      data: "lesson",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      if (!nextProps.auth.isAuthenticated) {
        return nextProps.history.push(
          `/login/?returnto=/lesson/${nextProps.id}`
        );
      }
      let update = {};
      if (!nextProps.id) {
        console.log("redirecting to 404", 4);
        nextProps.history.push("/404");
      }
      if (nextProps.auth) {
        update.auth = nextProps.auth;
      }
      return update;
    } else {
      return null;
    }
  }
  render() {
    return (
      <>
        {!this.state.is404 && this.state.auth.isAuthenticated && (
          <React.Fragment>
            <Helmet>
              <title>{i18n("lessontitle")}</title>
            </Helmet>
            {this.state.auth.isAuthenticated &&
              this.state.auth.user.type === "customer" && (
                <UserLesson id={this.state.id} />
              )}
            {this.state.auth.isAuthenticated &&
              this.state.auth.user.type === "coach" && (
                <CoachLesson id={this.state.id} />
              )}
          </React.Fragment>
        )}
        {this.state.is404 && (
          <>
            <h1 className="text-center">404 :(</h1>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  coach: state.coach,
});

Lesson.propTypes = {
  auth: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { setInfo })(withRouter(Lesson));
