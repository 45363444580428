const en = {
  account: "Account",
  login: "Login",
  signup: "Sign Up",
  logout: "Logout",
  idcreated: "Your Equireel ID has been created",
  ue: "undefined error",
  loggedin: "Logged In",
  unexpectedErrorContactSupport: "unexpected error. please contact support.",
  loginAgain: "Please Login Again",
  logoutSuccess: "See you soon :)",
  passwordChanged: "Password Changed",
  addedToCart: "Added To Cart",
  cartItemRemoved: "Cart Item Removed",
  emailOrderReceived:
    "Your Order Has Been Received. You will receive a confirmation email from us.",
  currencySwitched: "Currency Switched",
  errorSwitchCurrency: "Could not switch currency",
  errorInvalidEmailPass: "Invalid Email/Password",
  loginToEquireel: "Login to Equireel",
  loginTo: "Login to",
  registerat: "Register at",
  email: "Email",
  password: "Password",
  name: "Name",
  submit: "Submit",
  dontHaveId: "Don't have an Equireel ID?",
  alreadyHaveId: "Already have an Equireel ID?",
  nameatleast: "Name should be atleast 4 characters long",
  passwordatleast: "Password should be atleast 6 characters long",
  invalidEmail: "Invalid email",
  invalidPassword: "Invalid password",
  registerID: "Register an Equireel ID",
  copyrightText: `All Rights Reserved. Made with Love by`,
  invalidCurrentPassword: "Invalid Current Password",
  accountTitle: "Manage Account - Equireel",
  dashboard: "Dashboard",
  orders: "Orders",
  changePassword: "Change Password",
  hi: "Hi",
  canManageAccountHere: "You can manage Your Account Here.",
  seeYourOrders: "See your orders",
  andMore: "and more",
  orderkey: "Order Key",
  orderwillshowuphere: "Your orders will appear here",
  currentPassword: "Current Password",
  newpassword: "New Password",
  errorEnterPromoFirst: "Please enter a Promo first",
  promoapplied: "Promo Applied",
  couldnotverifypromo: "Could not verify Promo",
  logintoapplypromo: "Please login in order to apply promo",
  cartpagetitle: "Cart - Equireel",
  cart: "Cart",
  checkout: "Checkout",
  remove: "Remove",
  subtotal: "Subtotal",
  promocode: "Promo Code",
  promofieldplaceholder: "Enter Promo and press return",
  promodiscount: "Promo Discount",
  total: "Total",
  logintocheckout: "Login to Checkout",
  andapplypromocodes: "and apply promo codes",
  noitemsincart: "No Items found in Cart",
  filltoplaceorder: "Fill Out The Form Below To Order Your Video",
  selectcountry: "Select Country",
  selectevent: "Select Event",
  selecthorse: "Select Horse",
  selectvideotype: "Select Video Type",
  price: "Price",
  addtocart: "Add To Cart",
  orderdetailstitle: "Order Details - Equireel",
  backtoallorders: "Back to All Orders",
  orderitems: "Order Items",
  orderdetails: "Order Details",
  charged: "Charged",
  date: "Date",
  status: "Status",
  pagenotfound: "Page not found",
  languageSwitched: "Language Switched",
  losttheway: "Maybe you lost the way?...",
  gobackhome: "Go Back Home",
  forgotyourpassword: "Forgot Your Password?",
  resetPassword: "Reset Password",
  nouserwiththisemail: "No User With This Email Found",
  resetrequestcreatedmessage:
    "Password Reset Request has been created. Please check your email for the reset link. Be sure to check the spam folder.",
  passwordsnotmatching: "Passwords Could Not Match",
  confirmpassword: "Confirm Password",
  invaliddataprovided: "Invalid data provided",
  resetpasssuccess: "Password Has been reset. Please Login Now.",
  askresetpassword: "Please reset your account password in order to login.",
  askresetpasswordexplanation:
    "We made some changes to our system, this action is required to ensure your account security. This is just a one-time action.",
  selectseason: "Select Season",
  loading: "Loading",
  userwithemailexists: "a user with this email already exists",
  promoremoved: "Promo Removed",
  askshareconsent: "Public Video (untick if you want to keep video private)",
  shareconsent: "Share Consent",
  yes: "Yes",
  no: "No",
  home: "Home",
  fname: "First Name",
  lname: "Last Name",
  lengthfname: "First Name should be at least 3 Characters Long",
  lengthlname: "Last Name should be at least 3 Characters Long",
  address1: "Street Address",
  address2: "Street Address Line 2",
  city: "City",
  stateprovince: "State / Province",
  postalcode: "Postal Code",
  lengthaddress: "The address should be at least 2 Characters Long",
  requiredcountry: "Country is Required",
  requiredstate: "State is Required",
  requiredcity: "City is Required",
  requiredpostalcode: "Postal Code is Required",
  phonenumber: "Phone Number",
  requiredphonenumber: "Phone Number is Required",
  validphoneformat: "Please enter phone number in the following format:",
  editAccount: "Edit Account",
  save: "Save",
  idedited: "Account Edited Successfully",
  pleasecompleteprofile:
    "Please Complete your profile in order to place orders",
  clickhere: "Click Here",
  support: "Support",
  ticketswillshowuphere: "Your Support Tickets will show up here",
  createsupportticket: "Create Support Ticket",
  ticketdetailstitle: "Ticket Details",
  backtoaccount: "Go back to account",
  createTicketTitle: "Create Support Ticket",
  selecttickettype: "Select Ticket Type",
  order: "Order",
  technical: "Technical",
  tickettype: "Ticket Type",
  selectorder: "Select Order",
  tickettitle: "Ticket Title",
  tickettitleplaceholder: "Briefly explain your problem",
  ticketdescription: "Ticket Description",
  ticketdescriptionplaceholder: "Explain the problem in detail",
  createticket: "Create Ticket",
  lengthtickettitle: "Ticket title should contain atleast 5 characters",
  lengthticketdescription:
    "Ticket description should contain atleast 10 characters",
  tickettyperequired: "Ticket type is required",
  orderrequired: "Order is required",
  ticketreceived: "Your support ticket has been received",
  calendar: "Calendar",
  coach: "Coach",
  editcalendar: "Edit Calendar",
  lessons: "Lessons",
  viewlessons: "View Lessons",
  attachmentlibrary: "Attachment Library",
  selectavailabilityrange: "Select Your Availability Range",
  availabilityChanged: "Your Availability has been updated",
  bio: "Bio",
  lengthbio: "Bio must be atleast 10 characters long",
  coaches: "Coaches",
  ourcoaches: "Our Coaches",
  days: "Days",
  day: "Day",
  lessonavailablewithin: "Lesson Available In",
  today: "Today",
  lessonavailabletoday: "Lesson Available Today",
  nextavailableslot: "Next Available Slot:",
  lessonfee: "Lesson Fee",
  booknow: "Book Now",
  coachtitle: "Coach - Equireel",
  availableslots: "Available Slots",
  selectedslot: "Selected Slot",
  notselected: "Not Selected",
  fee: "Fee",
  book: "Book",
  logintobook: "Login to Book",
  emailBookingReceived:
    "Your Booking Has Been Received. You will receive a confirmation email from us.",
  selectaslot: "Please Select a Booking Slot",
  lessonswillshowuphere: "Your lessons will appear here",
  lesson: "Lesson",
  inprogress: "In Progress",
  backtoalllessons: "Back to Lessons",
  lessontitle: "Lesson - Equireel",
  emailcoach: "Email Coach",
  emailcustomer: "Email Customer",
  user: "User",
  lessondetails: "Lesson Details",
  lessonstatusupdated: "Lesson Status Updated",
  seeYourLessons: "See your lessons",
  xcvideos: "XC Videos",
  noslotsyet:
    "Created Slots will appear here. You have not created any slots yet",
  createdslots: "Available Slots:",
  addslot: "Add Slot",
  selectslotfirst: "Please select slot first",
  slotalreadyselected: "Slot already added",
  delete: "Delete",
  dontforgettosavechanges: "Please don't forget to save changes",
  downloadzoom: "Download Zoom",
  notprovidedyet: "Not provided yet",
  addlink: "Add Link",
  editlink: "Edit Link",
  zoommeetinglink: "Zoom Meeting Link",
  pleaseenterlink: "Please enter link",
  lessonlinkupdated: "Lesson link updated",
  slotdate: "Slot Date",
  slottime: "Slot Time",
  bookedon: "Booked on",
  change: "Change",
  upload: "Upload",
  list: "List",
  uploadfileshere: "Upload files here",
  fileuploaded: "File Uploaded",
  uploadingplzwait:
    "File(s) are being uploaded. Please wait and keep the page open...",
  filedeleted: "File Deleted",
  files: "Files",
  addfile: "Add File",
  fromlibrary: "From Library",
  backtofileslist: "Back to files list",
  addtolesson: "Add to lesson",
  fileadded: "File added",
  addfilefromlibrary: "Add file from library",
  uploadfile: "Upload File",
  bookagain: "Book Again",
  view: "View",
  uselessonidaspromo:
    "Use your Lesson ID as promo code to get 50% off while purchasing XC Video from us! Valid till",
  lessonid: "Lesson ID",
  paymenterror: "There was an error processing your payment",
  videoupload: "Video Upload",
  meetinglink: "Zoom Meeting Link",
  meetingid: "Zoom Meeting ID",
  meetingpassword: "Zoom Meeting Password",
  coaching: "Coaching",
  uploadvideofile: "Upload Video/File",
  pleaseemailyourcoach:
    "Please only Email your Coach if you're experiencing technical Issues or need assistance. All other messages and information should be added to your message box",
  viewupload: "View & Upload",
  viewlibrary: "View Library",
  faq: "FAQ's",
  lessonline1: "Thank you for booking a lesson with [COACH_NAME].",
  lessonline2:
    "You will need to download zoom in order to partake in the lesson, download link available below.",
  lessonline3:
    "The meeting link will be updated on the day of the lesson, all you have to do is click on this link 10 minutes before the lesson and the zoom meeting will open in the zoom app you downloaded.",
  lessonline4:
    "Please upload a maximum of 10 minutes footage below no later than the day before the lesson.",
  lessonline5:
    "Please ensure you number and name your Video files appropriately prior to Upload.",
  tellusaboutyourself: "Tell us about yourself",
  usercommentsplaceholder: "Tell the coach what you'd like to work on",
  addedit: "Add / Edit",
  updated: "Updated",
  comments: "Comments",
  nousercomment:
    "What level are you currently competing at and is there anything specific that you'd like guidance on?",
  lessonscoaches: "Lessons / Coaches",
  selectlesson: "Select Lesson",
  lessonrequired: "Lesson is required",
  id: "ID",
  ticketdetails: "Ticket Details",
  messages: "Messages",
  orderdetailsparagraph:
    "Note: XC Videos can take up to 7 days to process, once completed your video will be sent via email.",
  vieworder: "View Order",
  writeyourmessagehere: "Write your message here",
  send: "Send",
  writeamessagetostartconversation: "Write a message to start the conversation",
  ticketstatusupdated: "Ticket status updated",
  general: "General",
  promolimitreached: "Promo Limit Reached",
  promoexpired: "Promo Expired",
  filladditionaldata: "Please fill in all the fields",
  askincurfaults: "Include Faults (if incurred)",
  null: "",
  ordervideos: "Order Videos",
  sthwentwrong: "Something Went Wrong, Sorry :(",
  reloadthepage: "Please reload the page.",
  or: "Or"
};

export default en;