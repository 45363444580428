import { SET_INFO } from "../types";

const initialState = {
  currentPage: "",
  title: ""
};

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO:
      return {
        ...state,
        [action.payload.for]: action.payload.data
      };
    default:
      return state;
  }
};

export default infoReducer;