import { SET_USER_TICKETS, UNSET_INITIALIZED_SUPPORT } from "../types";

const initialState = {
  userTickets: [],
  initialized: [],
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_TICKETS:
      return {
        ...state,
        userTickets: action.payload,
        initialized: [...state.initialized, "userTickets"],
      };

    case UNSET_INITIALIZED_SUPPORT:
      let initialized = state.initialized;
      initialized.splice(initialized.indexOf(action.payload), 1);
      return {
        ...state,
        initialized,
      };

    default:
      return state;
  }
};

export default supportReducer;