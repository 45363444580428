/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setInfo } from "../inc/actions/infoActions";
import TextareaAutosize from "react-textarea-autosize";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import { getRecords } from "../inc/actions/recordActions";
import { addToCart } from "../inc/actions/cartActions";

import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";

import Select from "react-virtualized-select";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      season: "",
      country: "",
      event: "",
      horse: "",
      videoType: "",
      additionalInfo: "",
      records: {},
      seasonOptions: [],
      countryOptions: [],
      eventOptions: [],
      horseOptions: [],
      videoTypeOptions: [],
      placeholder: "Horse Name",
      price: false,
      currency: "Pound",
    };
    this.props.setInfo({
      for: "currentPage",
      data: "home",
    });
    this.props.getRecords();
  }
  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.records) {
        update.records = nextProps.records.records;
        let records = Object.values(update.records);
        let structuredRecords = {};
        for (let index = 0; index < records.length; index++) {
          let record = records[index];
          if (
            Object.keys(structuredRecords).indexOf(String(record.Season)) === -1
          ) {
            structuredRecords[record.Season] = {};
          }
          if (!structuredRecords[record.Season][record.Country]) {
            structuredRecords[record.Season] = {
              ...structuredRecords[record.Season],
              [record.Country]: {},
            };
          }
          if (!structuredRecords[record.Season][record.Country][record.Event]) {
            structuredRecords[record.Season][record.Country] = {
              ...structuredRecords[record.Season][record.Country],
              [record.Event]: {},
            };
          }
          if (
            !structuredRecords[record.Season][record.Country][record.Event][
              record.Horse
            ]
          ) {
            structuredRecords[record.Season][record.Country][record.Event] = {
              ...structuredRecords[record.Season][record.Country][record.Event],
              [record.Horse]: {},
            };
          }
          if (
            !structuredRecords[record.Season][record.Country][record.Event][
              record.Horse
            ][record.Product]
          ) {
            structuredRecords[record.Season][record.Country][record.Event][
              record.Horse
            ] = {
              ...structuredRecords[record.Season][record.Country][record.Event][
                record.Horse
              ],
              [record.Product]: {},
            };
          }
          if (
            !structuredRecords[record.Season][record.Country][record.Event][
              record.Horse
            ][record.Product]["placeholder"]
          ) {
            structuredRecords[record.Season][record.Country][record.Event][
              record.Horse
            ][record.Product]["placeholder"] = record.Placeholder;
          }
          if (
            !structuredRecords[record.Season][record.Country][record.Event][
              record.Horse
            ][record.Product]["price"]
          ) {
            structuredRecords[record.Season][record.Country][record.Event][
              record.Horse
            ][record.Product]["price"] = record.Price;
          }
        }
        update.records = structuredRecords;
        let seasonOptions = [];
        for (
          let index = 0;
          index < Object.keys(structuredRecords).length;
          index++
        ) {
          const element = Object.keys(structuredRecords)[index];
          console.log('going through records', element);
          seasonOptions.push({
            label: element,
            value: element,
          });
        }
        seasonOptions.sort(function (a, b) {
            if (Number(a.label) < Number(b.label)) {
                return 1;
            }
            if (Number(a.label) > Number(b.label)) {
                return -1;
            }
            return 0;
        });
        update.seasonOptions = seasonOptions;
      }
      if (nextProps.cart.currency) {
        update.currency = nextProps.cart.currency;
      }
      if (nextProps.auth.isAuthenticated) {
        if (nextProps.auth.user.type === "coach") {
          nextProps.history.push("/account/dashboard");
        }
      }
      if (nextProps.auth.user && nextProps.auth.user.type === "coach") {
        return nextProps.history.push(`/account/`);
      }
      return update;
    } else {
      return null;
    }
  }
  onChangeSelect = (input, val, isText = false) => {
    if (val) {
      this.setState(() => {
        return {
          [input]: isText ? val : val.value,
        };
      });
    } else {
      this.setState(() => {
        return {
          [input]: "",
        };
      });
    }
    if (input === "season") {
      if (val) {
        let countryOptions = [];
        for (
          let index = 0;
          index < Object.keys(this.state.records[val.value]).length;
          index++
        ) {
          const element = Object.keys(this.state.records[val.value])[index];
          countryOptions.push({
            label: element,
            value: element,
          });
        }
        this.setState(() => {
          return {
            ...this.state,
            countryOptions: countryOptions,
            country: "",
            event: "",
            horse: "",
            videoType: "",
            additionalInfo: "",
            price: false,
            season: val.value,
          };
        });
      } else {
        this.setState(() => {
          return {
            ...this.state,
            event: "",
            horse: "",
            videoType: "",
            additionalInfo: "",
            country: "",
            eventOptions: [],
            horseOptions: [],
            videoTypeOptions: [],
            price: false,
            placeholder: "Horse Name",
          };
        });
      }
    }
    if (input === "country") {
      if (val) {
        let eventOptions = [];
        for (
          let index = 0;
          index <
          Object.keys(this.state.records[this.state.season][val.value]).length;
          index++
        ) {
          const element = Object.keys(
            this.state.records[this.state.season][val.value]
          )[index];
          eventOptions.push({
            label: element,
            value: element,
          });
        }
        eventOptions.sort(function (a, b) {
          if (a.label.charAt(0) < b.label.charAt(0)) {
            return -1;
          }
          if (a.label.charAt(0) > b.label.charAt(0)) {
            return 1;
          }
          return 0;
        });
        this.setState(() => {
          return {
            ...this.state,
            eventOptions: eventOptions,
            event: "",
            horse: "",
            videoType: "",
            additionalInfo: "",
            price: false,
            country: val.value,
          };
        });
      } else {
        this.setState(() => {
          return {
            ...this.state,
            event: "",
            horse: "",
            videoType: "",
            additionalInfo: "",
            country: "",
            eventOptions: [],
            horseOptions: [],
            videoTypeOptions: [],
            price: false,
            placeholder: "Horse Name",
          };
        });
      }
    }
    if (input === "event") {
      if (val) {
        let horseOptions = [];
        for (
          let index = 0;
          index <
          Object.keys(
            this.state.records[this.state.season][this.state.country][val.value]
          ).length;
          index++
        ) {
          const element = Object.keys(
            this.state.records[this.state.season][this.state.country][val.value]
          )[index];
          horseOptions.push({
            label: element,
            value: element,
          });
        }
        let videoTypeOptions = [];
        for (
          let index = 0;
          index <
          Object.keys(
            this.state.records[this.state.season][this.state.country][
              val.value
            ][horseOptions[0].value]
          ).length;
          index++
        ) {
          const element = Object.keys(
            this.state.records[this.state.season][this.state.country][
              val.value
            ][horseOptions[0].value]
          )[index];
          videoTypeOptions.push({
            label: element,
            value: element,
          });
        }
        this.setState(() => {
          return {
            ...this.state,
            horseOptions: horseOptions,
            horse: horseOptions[0].value,
            videoType: "",
            additionalInfo: "",
            price: false,
            event: val.value,
            videoTypeOptions,
          };
        });
      } else {
        this.setState(() => {
          return {
            ...this.state,
            event: "",
            horse: "",
            videoType: "",
            additionalInfo: "",
            horseOptions: [],
            videoTypeOptions: [],
            price: false,
            placeholder: "Horse Name",
          };
        });
      }
    }
    if (input === "horse") {
      if (val) {
        let videoTypeOptions = [];
        for (
          let index = 0;
          index <
          Object.keys(
            this.state.records[this.state.season][this.state.country][
              this.state.event
            ][val.value]
          ).length;
          index++
        ) {
          const element = Object.keys(
            this.state.records[this.state.season][this.state.country][
              this.state.event
            ][val.value]
          )[index];
          videoTypeOptions.push({
            label: element,
            value: element,
          });
        }
        this.setState(() => {
          return {
            ...this.state,
            videoTypeOptions: videoTypeOptions,
            horse: val.value,
            additionalInfo: "",
            price: false,
            videoType: "",
          };
        });
      } else {
        this.setState(() => {
          return {
            ...this.state,
            horse: "",
            videoType: "",
            additionalInfo: "",
            videoTypeOptions: [],
            placeholder: "Horse Name",
            price: false,
          };
        });
      }
    }
    if (input === "videoType") {
      if (val) {
        let placeholder = this.state.records[this.state.season][
          this.state.country
        ][this.state.event][this.state.horse][val.value].placeholder;
        let price = this.state.records[this.state.season][this.state.country][
          this.state.event
        ][this.state.horse][val.value].price;
        this.setState(() => {
          return {
            ...this.state,
            videoType: val.value,
            placeholder,
            price,
          };
        });
      } else {
        this.setState(() => {
          return {
            ...this.state,
            videoType: "",
            additionalInfo: "",
            placeholder: "Horse Name",
            price: false,
          };
        });
      }
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    let {
      season,
      country,
      event,
      horse,
      videoType,
      additionalInfo,
      price,
    } = this.state;
    let itemData = {
      Season: season,
      Country: country,
      Event: event,
      Horse: horse,
      Product: videoType,
      Info: additionalInfo,
      Price: price,
    };
    if (!additionalInfo) return toast.error(i18n("filladditionaldata"));
    this.props.addToCart(itemData, "cart", this.props.history);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Equireel</title>
        </Helmet>
        <Container className="form-container">
          <div className="pageTitle">
            <h2>{i18n("filltoplaceorder")}</h2>
          </div>
          <div className="formWrap card">
            <form
              onSubmit={this.onSubmit}
              autoComplete="off"
              className="cardContent withPadding"
            >
              <Row>
                <Col md={4} sm={12}>
                  <Select
                    options={
                      this.state.seasonOptions ? this.state.seasonOptions : null
                    }
                    onChange={(selectedseason) =>
                      this.onChangeSelect("season", selectedseason)
                    }
                    value={this.state.season}
                    placeholder={i18n("selectseason")}
                  />
                </Col>
                <Col md={4} sm={12}>
                  <Select
                    options={
                      this.state.countryOptions
                        ? this.state.countryOptions
                        : null
                    }
                    onChange={(selectedCountry) =>
                      this.onChangeSelect("country", selectedCountry)
                    }
                    value={this.state.country}
                    placeholder={i18n("selectcountry")}
                    disabled={this.state.season.length === 0 ? true : false}
                  />
                </Col>
                <Col md={4} sm={12}>
                  <Select
                    options={
                      this.state.eventOptions ? this.state.eventOptions : {}
                    }
                    onChange={(selectedEvent) =>
                      this.onChangeSelect("event", selectedEvent)
                    }
                    value={this.state.event}
                    placeholder={i18n("selectevent")}
                    disabled={this.state.country.length === 0 ? true : false}
                    optionHeight={(o) => {
                      return o.option.label.length > 38 ? 55 : 35;
                    }}
                  />
                </Col>
                {/* <Col md={4} sm={12}>
                  <Select
                    options={this.state.horseOptions}
                    onChange={(selectedHorse) =>
                      this.onChangeSelect("horse", selectedHorse)
                    }
                    value={this.state.horse}
                    placeholder={i18n("selecthorse")}
                    disabled={
                      this.state.country.length === 0 ||
                      this.state.event.length === 0
                        ? true
                        : false
                    }
                  />
                </Col> */}
                <Col md={6} sm={12}>
                  <Select
                    options={this.state.videoTypeOptions}
                    onChange={(selectedVideotype) =>
                      this.onChangeSelect("videoType", selectedVideotype)
                    }
                    value={this.state.videoType}
                    placeholder={i18n("selectvideotype")}
                    disabled={
                      this.state.country.length === 0 ||
                      this.state.event.length === 0 ||
                      this.state.horse.length === 0
                        ? true
                        : false
                    }
                  />
                </Col>
                <Col md={6} sm={12}>
                  <TextareaAutosize
                    name="additionalInfo"
                    cols="30"
                    rows="10"
                    placeholder={
                      this.state.placeholder === "horse name"
                        ? "Horse Name"
                        : this.state.placeholder
                    }
                    className="form-control textarea"
                    disabled={
                      this.state.country.length === 0 ||
                      this.state.event.length === 0 ||
                      this.state.horse.length === 0 ||
                      this.state.videoType.length === 0
                        ? true
                        : false
                    }
                    onBlur={(e) =>
                      this.setState({
                        ...this.state,
                        additionalInfo: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col sm={12}>
                  {this.state.price && (
                    <div className="add-to-cart">
                      <div className="price">
                        <span className="label">{i18n("price")}: </span>
                        <span className="value">
                          {this.state.price.toFixed(2)}
                          <span className="currency">
                            {this.state.currency}
                          </span>
                        </span>
                      </div>
                      <div className="submit-form">
                        <input
                          className="text submit"
                          type="submit"
                          value={i18n("addtocart")}
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </form>
          </div>
          <div className="banner">
            <img
              src="/assets/images/equireel-membership-banner.jpg"
              alt="banner"
            />
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

Home.propTypes = {
  auth: PropTypes.object.isRequired,
  getRecords: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  records: state.records,
  cart: state.cart,
});

export default connect(mapStateToProps, { setInfo, getRecords, addToCart })(
  withRouter(Home)
);
