/* global i18n */
import React from "react";
import { Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../inc/actions/authActions";
import { setInfo } from "../../inc/actions/infoActions";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { toast } from "react-toastify";

// import LogoIcon from "../../assets/Logo.png";
import { checkMinLength, checkEmail } from "../../inc/Validation";

class Login extends React.Component {
  constructor(props) {
    super(props);
    let returnto = queryString.parse(props.location.search).returnto;
    returnto = returnto ? returnto : "/";
    this.state = {
      email: "",
      password: "",
      isLoggedIn: false,
      returnto,
    };
    this.props.setInfo({
      for: "currentPage",
      data: "login",
    });
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.auth.isAuthenticated) {
      nextProps.history.push("/");
    }
    if (nextProps !== prevProps) {
      return {
        ...prevProps,
      };
    }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validate = () => {
    var errorMsg = "";
    const { email, password } = this.state;
    if (!email || !checkEmail(email)) {
      errorMsg = i18n("errorInvalidEmailPass");
      toast.error(errorMsg);
      return false;
    }
    if (!password || !checkMinLength(password, 6)) {
      errorMsg = i18n("errorInvalidEmailPass");
      toast.error(errorMsg);
      return false;
    }
    return true;
  };
  submitHandler = (e) => {
    e.preventDefault();
    const { email, password, returnto } = this.state;
    if (this.validate()) {
      const setUser = {
        email,
        password,
      };
      this.props.loginUser(setUser, this.props.history, returnto);
    } else {
      return false;
    }
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n("loginToEquireel")}</title>
        </Helmet>
        <Container className="fullscreenCenter vh70">
          <div className="loginWrap">
            <div className="brandIcon">
              <p className="label">{i18n("loginTo")} Equireel</p>
            </div>
            <form action="post" onSubmit={this.submitHandler}>
              <input
                onChange={this.changeHandler}
                name="email"
                className="form-control email"
                type="text"
                placeholder={i18n("email")}
                value={this.state.email}
              />
              <input
                onChange={this.changeHandler}
                name="password"
                className="form-control password"
                type="password"
                placeholder={i18n("password")}
                value={this.state.password}
              />
              <input
                className="button bg-accent"
                type="submit"
                value={i18n("submit")}
              />
            </form>
            <p>
              <Link to="/forgotpassword">{i18n("forgotyourpassword")}</Link>
            </p>
            <p>
              <Link
                to={`/register${
                  this.state.returnto ? "?returnto=" + this.state.returnto : ""
                }`}
              >
                {i18n("dontHaveId")}
              </Link>
            </p>
          </div>
          <div className="spacer"></div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
});

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { loginUser, setInfo })(
  withRouter(Login)
);
