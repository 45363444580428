import en from './i18n/en'
import fr from './i18n/fr'
import Moment from 'react-moment'
import Emitter from "./emitter";

const languages = {
    en,
    fr
};

const LANG = {
  FR: 'fr',
  EN: 'en'
}

export const EU_PoundCountryCapitals = ['London'];

let defaultLanguage = LANG.EN;

export const getBrowserDefaultLanguage = () => {
  let lang = LANG.EN;
  switch (window.navigator.language) {
    case "fr":
    case "fr-CA":
    case "fr-CH":
    case "fr-BE":
    case "fr-LU":
    case "fr-FR":
      lang = LANG.FR;
      break;
  
    default:
      lang = LANG.EN;
      break;
  }
  return lang;
}

export const detectUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const detectUserRegion = () => {
  return detectUserTimezone().split('/')[0];
}

/**
 * Detects user city from timezone
 * @warning cannot be used to detect precise location of user
 * @returns String
 */
export const detectUserCity = () => {
  return detectUserTimezone().split('/')[1];
}

const _decideDefaultLanguage = () => {
  const browserDefault = getBrowserDefaultLanguage();
  const userCity = detectUserCity();
  let verdict = browserDefault;
  switch(userCity) {
    case "Paris":
      verdict = LANG.FR;
      break;

    default:
      break;
  }
  return verdict;
}

defaultLanguage = localStorage.getItem('lang') ? localStorage.getItem('lang') : _decideDefaultLanguage();

window.i18nData = languages[defaultLanguage];
window.i18nlang = defaultLanguage;
Moment.globalLocale = defaultLanguage;

window.i18n = (key) => window.i18nData[key];

window.changeLanguage = (lang) => {
    window.i18nData = languages[lang];
    window.i18nlang = lang;
    Moment.globalLocale = lang;
    localStorage.setItem('lang', lang);
    Emitter.emit("LANG_UPDATE", lang);
}