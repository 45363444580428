/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { setInfo } from "../inc/actions/infoActions";

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.props.setInfo({
            for: "currentPage",
            data: "404"
        });
    }

    render() {
        return (
            <>
                <Helmet><title>{`404 ${i18n('pagenotfound')} - Equireel`}</title></Helmet>
                <Container className="Wrap404 vh70 fullscreenCenter">
                    <Row>
                        <Col>
                            <h1 className="megaTitle">404</h1>
                            <h2>{i18n('losttheway')} <Link to="/">{i18n('gobackhome')}</Link></h2>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

NotFound.propTypes = {
    setInfo: PropTypes.func.isRequired
};

export default connect(null, { setInfo })(
    withRouter(NotFound)
);
