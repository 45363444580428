/* global i18n */
import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { unsetUser } from "../../inc/actions/authActions";
import { switchCurrency } from "../../inc/actions/cartActions";
import Logo from "./../../assets/Logo.png";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartCount: 0,
      currency: {
        code: "EUR",
        rate: 1,
      },
      navExpanded: false,
    };
  }
  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.cart.cart) {
        update.cart = nextProps.cart.cart;
        update.cartCount = update.cart.length;
      }
      if (nextProps.cart.currency) {
        update.currency = nextProps.cart.currency;
      }
      return update;
    } else {
      return null;
    }
  }
  logoutUser = (e) => {
    e.preventDefault();
    this.props.unsetUser("/", this.props.history);
    this.closeNav();
    return false;
  };
  switchCurrency = (e, to) => {
    e.preventDefault();
    this.props.switchCurrency(to, true);
    return false;
  };
  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  closeNav = () => {
    this.setState({ navExpanded: false });
  };
  render() {
    const { isAuthenticated, user } = this.props.auth;
    const guestLinks = (
      <React.Fragment>
        <Link
          to="/"
          onClick={this.closeNav}
          className={
            this.props.info.currentPage === "home"
              ? "nav-link bg-accent"
              : "nav-link"
          }
        >
          {i18n("ordervideos")}
        </Link>
        {/* <Link
          to="/coaches/"
          onClick={this.closeNav}
          className={
            this.props.info.currentPage === "coachListing" ||
            this.props.info.currentPage === "coach"
              ? "nav-link bg-accent"
              : "nav-link"
          }
        >
          {i18n("coaching")}
        </Link> */}
        <Link
          to="/login"
          onClick={this.closeNav}
          className={
            this.props.info.currentPage === "login"
              ? "nav-link bg-accent"
              : "nav-link"
          }
        >
          {i18n("login")}
        </Link>
        <Link
          to="/register"
          onClick={this.closeNav}
          className={
            this.props.info.currentPage === "register"
              ? "nav-link bg-accent"
              : "nav-link"
          }
        >
          {i18n("signup")}
        </Link>
      </React.Fragment>
    );
    const userLinks = (
      <React.Fragment>
        {user &&
          user.type === "customer" && [
            <Link
              to="/"
              onClick={this.closeNav}
              key="xcvideos"
              className={
                this.props.info.currentPage === "home"
                  ? "nav-link bg-accent"
                  : "nav-link"
              }
            >
              {i18n("ordervideos")}
            </Link>,
          ]}
        <Link
          to="/account"
          onClick={this.closeNav}
          className={
            this.props.info.currentPage === "account"
              ? "nav-link bg-accent"
              : "nav-link"
          }
        >
          {i18n("account")}
        </Link>
      </React.Fragment>
    );
    return (
      <header>
        <Container className="navbarSection">
          <Navbar
            collapseOnSelect
            expand="lg"
            onToggle={this.setNavExpanded}
            expanded={this.state.navExpanded}
          >
            {user.type === "customer" && (
              <Link
                to="/cart"
                onClick={this.closeNav}
                className={"cart-icon nav-icon hide-on-large"}
              >
                <i className="las la-shopping-basket"></i>
                {this.state.cartCount > 0 && (
                  <div className="count">{this.state.cartCount}</div>
                )}
              </Link>
            )}
            {!user && (
              <Link
                to="/cart"
                onClick={this.closeNav}
                className={"cart-icon nav-icon hide-on-large"}
              >
                <i className="las la-shopping-basket"></i>
                {this.state.cartCount > 0 && (
                  <div className="count">{this.state.cartCount}</div>
                )}
              </Link>
            )}
            <Navbar.Brand>
              <a href={process.env.REACT_APP_MAIN_DOMAIN_URL}>
                <img
                  src={Logo}
                  alt="Equireel"
                  className="img-fluid"
                  onClick={this.closeNav}
                />
              </a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <div className="ml-auto navWrap">
                {user.type === "customer" && (
                  <Link
                    to="/cart"
                    className={"cart-icon nav-icon m-auto hide-on-small"}
                    onClick={this.closeNav}
                  >
                    <i className="las la-shopping-basket"></i>
                    {this.state.cartCount > 0 && (
                      <div className="count">{this.state.cartCount}</div>
                    )}
                  </Link>
                )}
                {!user && (
                  <Link
                    to="/cart"
                    className={"cart-icon nav-icon m-auto hide-on-small"}
                    onClick={this.closeNav}
                  >
                    <i className="las la-shopping-basket"></i>
                    {this.state.cartCount > 0 && (
                      <div className="count">{this.state.cartCount}</div>
                    )}
                  </Link>
                )}
                <Nav className="sessionActions" onSelect={this.closeNav}>
                  <a
                    href={process.env.REACT_APP_MAIN_DOMAIN_URL}
                    className="nav-link"
                  >
                    {i18n("home")}
                  </a>
                  {isAuthenticated ? userLinks : guestLinks}
                </Nav>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  cart: state.cart,
});

Header.propTypes = {
  unsetUser: PropTypes.func.isRequired,
  switchCurrency: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { unsetUser, switchCurrency })(
  withRouter(Header)
);
