import { SET_USER, UNSET_USER } from "../types";

const initialState = {
  isAuthenticated: false,
  user: false,
  profileComplete: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        profileComplete:
          action.payload.type === "customer"
            ? action.payload.fname !== "" &&
              action.payload.lname !== "" &&
              action.payload.email !== "" &&
              action.payload.phone !== ""
              ? true
              : false
            : true,
      };
    case UNSET_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: false,
        profileComplete: false,
      };
    default:
      return state;
  }
};

export default authReducer;