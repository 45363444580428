/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

import { setInfo } from "../inc/actions/infoActions";
import {
  removeFromCart,
  doCheckout,
  verifyPromo,
  createCheckoutSession,
} from "../inc/actions/cartActions";

import {stripeKeys} from "../inc/reducers/cartReducer";

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: [],
      subtotal: 0,
      promoApplied: false,
      total: 0,
      auth: false,
      promoVal: "",
      newTotal: false,
      currency: "Pound",
      shareconsent: true,
      includefaults: false,
      stripePublicKey: stripeKeys["Pound"],
      stripe: loadStripe(stripeKeys["Pound"])
    };
    this.props.setInfo({
      for: "currentPage",
      data: "cart",
    });
  }
  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.auth.user && nextProps.auth.user.type === "coach") {
      return nextProps.history.push(`/account/`);
    }
    if (nextProps !== prevProps) {
      let update = {};
      if (nextProps.cart.cart) {
        update.cart = nextProps.cart.cart;
        let subtotal = 0;
        for (let index = 0; index < update.cart.length; index++) {
          const cart = update.cart[index];
          subtotal = subtotal + cart.Price;
        }
        update.subtotal = subtotal;
        update.total = subtotal;
      }
      if (nextProps.cart.currency) {
        update.currency = nextProps.cart.currency;
      }
      if (nextProps.cart.stripePublicKey) {
        update.stripePublicKey = nextProps.cart.stripePublicKey;
        update.stripe = loadStripe(nextProps.cart.stripePublicKey);
      }
      if (nextProps.auth) {
        update.auth = nextProps.auth;
      }
      return update;
    } else {
      return null;
    }
  }

  removeItem = (key) => {
    this.props.removeFromCart(key);
  };
  toggleConsent = () => {
    this.setState({
      ...this.state,
      shareconsent: !this.state.shareconsent,
    });
  };
  toggleIFConsent = () => {
    this.setState({
      ...this.state,
      includefaults: !this.state.includefaults,
    });
  };

  sendCheckoutSessionRequest = async () => {
    let { cart, total, newTotal, shareconsent, includefaults } = this.state;
    total = newTotal !== false ? newTotal : total;
    let cartjson = JSON.stringify(cart);
    let currency;
    switch (this.state.currency) {
        case "Euro":
            currency = "EUR";
            break;
        case "Pound":
            currency = "GBP";
            break;
        case "USD":
          currency = "USD";
          break;
        default:
            currency = "GBP";
            break;
    }
    const newCart = [];
    cart.map((cartitem) => {
      const item = {
        price_data: {
          currency,
          product_data: {
            name: cartitem.Event,
            metadata: {
              country: cartitem.Country,
              info: cartitem.Info,
              product: cartitem.Product,
              season: cartitem.Season,
            },
          },
          unit_amount_decimal: Number(cartitem.Price * 100).toFixed(2),
        },
        quantity: 1,
      };
      newCart.push(item);
      return true;
    });
    const line_items = JSON.stringify(newCart);
    const data = {
      line_items,
      cart: cartjson,
      total,
      currency: this.state.currency,
      shareconsent,
      includefaults
    };
    axios
      .post(
        process.env.REACT_APP_API_PATH + "orders/createcheckoutsession",
        data
      )
      .then(async (res) => {
        const session = res.data;
        const stripe = await this.state.stripe;
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
        if (result.error) {
          console.error(result.error);
          toast.error(result.error.message);
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
      });
  };

  onToken = (token, addresses) => {
    // console.log(token, addresses);
    let { cart, total, newTotal, promo, shareconsent, includefaults } =
      this.state;
    let stripe = [token, addresses];
    total = newTotal !== false ? newTotal : total;
    stripe = JSON.stringify(stripe);
    cart = JSON.stringify(cart);
    this.props.doCheckout(
      cart,
      total,
      stripe,
      promo,
      this.state.currency,
      shareconsent,
      includefaults,
      this.props.history
    );
  };

  render() {
    const consentToggled = this.state.shareconsent;
    const consentIFToggled = this.state.includefaults;
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n("cartpagetitle")}</title>
        </Helmet>
        <Container className="cartWrap">
          <Row>
            {this.state.cart.length > 0 && (
              <>
                <Col md={8} sm={12}>
                  <div className="card cart-table">
                    <div className="cardTitle">{i18n("cart")}</div>
                    <div className="cardContent">
                      <table className="table table-responsive color-text">
                        <tbody>
                          {this.state.cart.map((item) => (
                            <tr key={this.state.cart.indexOf(item)}>
                              <td className="itemInfo">
                                <ul>
                                  {Object.entries(item).map((val, key) => [
                                    val[0] !== "Price" && val[0] !== "Horse" && (
                                      <li key={key}>
                                        <span className="label">
                                          {val[0] + ":"}
                                        </span>
                                        <span
                                          className="value"
                                          dangerouslySetInnerHTML={{
                                            __html: val[1] ? val[1] : "N/A",
                                          }}
                                        ></span>
                                      </li>
                                    ),
                                  ])}
                                </ul>
                              </td>
                              <td>
                                <span className="price">
                                  <span className="value">
                                    {item.Price.toFixed(2)}
                                  </span>
                                  <span className="currency">
                                    {this.state.currency}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span
                                  className="remove"
                                  onClick={(key) =>
                                    this.removeItem(
                                      this.state.cart.indexOf(item)
                                    )
                                  }
                                >
                                  {i18n("remove")}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  <div className="card checkoutTable">
                    <div className="cardTitle">{i18n("checkout")}</div>
                    <div className="cardContent infoList withPadding">
                      <ul className="contentList">
                        <li>
                          <div className="label">{i18n("subtotal")}</div>
                          <div className="value">
                            <div className="price">
                              <span className="value">
                                {this.state.subtotal.toFixed(2)}
                              </span>
                              <span className="currency">
                                {this.state.currency}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="label">{i18n("total")}</div>
                          <div className="value">
                            <div className="price">
                              <span className="value">
                                {(this.state.newTotal !== false
                                  ? this.state.newTotal
                                  : this.state.total
                                ).toFixed(2)}
                              </span>
                              <span className="currency">
                                {this.state.currency}
                              </span>
                            </div>
                          </div>
                        </li>
                        {this.state.auth.isAuthenticated &&
                          this.state.auth.profileComplete && (
                            <>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="shareconsent"
                                  onChange={this.toggleConsent}
                                  id="shareconsent"
                                  checked={consentToggled ? "checked" : ""}
                                />
                                <label
                                  htmlFor="shareconsent"
                                  className="form-check-label"
                                >
                                  {i18n("askshareconsent")}
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="includefaults"
                                  onChange={this.toggleIFConsent}
                                  id="includefaults"
                                  checked={consentIFToggled ? "checked" : ""}
                                />
                                <label
                                  htmlFor="includefaults"
                                  className="form-check-label"
                                >
                                  {i18n("askincurfaults")}
                                </label>
                              </div>
                              <button
                                className="btn bg-accent color-text checkoutLink"
                                onClick={this.sendCheckoutSessionRequest}
                              >
                                {i18n("checkout")}
                              </button>
                            </>
                          )}
                        {!this.state.auth.isAuthenticated && (
                          <>
                            <Link
                              className="btn bg-accent color-text checkoutLink loginLink"
                              to="/login?returnto=/cart/"
                            >
                              {i18n("logintocheckout")}
                            </Link>
                          </>
                        )}
                        {this.state.auth.isAuthenticated &&
                          !this.state.auth.profileComplete && (
                            <>
                              <Link
                                className="btn bg-accent color-text checkoutLink editAccountLink"
                                to="/account/profile"
                              >
                                {i18n("editAccount")}
                              </Link>
                              <p
                                style={{ textAlign: "center", marginBottom: 0 }}
                              >
                                <small>{i18n("pleasecompleteprofile")}</small>
                              </p>
                            </>
                          )}
                      </ul>
                    </div>
                  </div>
                </Col>
              </>
            )}
            {this.state.cart.length < 1 && (
              <>
                <Container className="fullscreenCenter vh70">
                  <h3>{i18n("noitemsincart")}</h3>
                  <div className="spacer"></div>
                </Container>
              </>
            )}
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

Cart.propTypes = {
  auth: PropTypes.object.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  doCheckout: PropTypes.func.isRequired,
  verifyPromo: PropTypes.func.isRequired,
  createCheckoutSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  cart: state.cart,
});

export default connect(mapStateToProps, {
  setInfo,
  removeFromCart,
  doCheckout,
  verifyPromo,
  createCheckoutSession,
})(withRouter(Cart));
