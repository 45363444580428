/* global i18n */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import "moment-timezone";
import TextareaAutosize from "react-textarea-autosize";

import Emitter from "./../inc/emitter";
import { setInfo } from "../inc/actions/infoActions";
import {
  getUserTickets,
  sendMessage,
  changeTicketStatus,
} from "../inc/actions/supportActions";

class Ticket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is404: false,
      ticket: {},
      newmessage: {},
    };
    this.props.setInfo({
      for: "currentPage",
      data: "ticket",
    });
    this.props.getUserTickets();
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    let update = {};
    if (nextProps.id) {
      if (nextProps.support) {
        if (nextProps.support.initialized.indexOf("userTickets") !== -1) {
          if (nextProps.support.userTickets) {
            let userTickets = nextProps.support.userTickets;
            let found = false;
            for (let index = 0; index < userTickets.length; index++) {
              let userTicket = userTickets[index];
              if (userTicket._id === nextProps.id) {
                update.id = nextProps.id;
                update.is404 = false;
                found = true;
                update.ticket = userTicket;
              }
            }
            update.got = true;
            update.userTickets = userTickets;
            if (!found && update.got) {
              console.log("redirecting to 404", 1);
              nextProps.history.push("/404");
            }
          } else {
            console.log("redirecting to 404", 2);
            nextProps.history.push("/404");
          }
        }
      }
    } else {
      console.log("redirecting to 404", 4);
      nextProps.history.push("/404");
    }
    if (nextProps.auth) {
      update.auth = nextProps.auth;
    }
    if (!nextProps.auth.isAuthenticated) {
      nextProps.history.push(`/login/?returnto=/ticket/${nextProps.id}`);
    }
    return update;
  }

  componentDidMount() {
    this.scrollToBottom();
    Emitter.on("CHAT_MSG", (msg) => {
      let messages = this.state.ticket.messages;
      messages.push(msg.message);
      this.setState({
        ...this.state,
        ticket: {
          ...this.state.ticket,
          messages,
        },
      });
      this.scrollToBottom();
    });
  }
  componentWillUnmount() {
    Emitter.off("CHAT_MSG");
  }
  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  addMessage = () => {
    let message = document.querySelector("#newMessageField").value;
    if (!message || message === "") {
      return false;
    }
    this.props.sendMessage(this.state.ticket._id, {
      message,
      from: "user",
      date: new Date(),
    });
    document.querySelector("#newMessageField").value = "";
  };
  showChange = () => {
    this.setState({
      ...this.state,
      inputToggled: true,
    });
  };
  changeStatus = (val) => {
    this.props.changeTicketStatus(this.state.ticket._id, val);
    this.props.getUserTickets();
    this.setState({
      ...this.state,
      inputToggled: false,
      ticket: {
        ...this.state.ticket,
        status: val,
      },
    });
  };
  render() {
    const { ticket } = this.state;
    return (
      <>
        {!this.state.is404 &&
          this.state.auth &&
          this.state.auth.isAuthenticated && (
            <React.Fragment>
              <Helmet>
                <title>{i18n("ticketdetailstitle")}</title>
              </Helmet>
              {this.state.ticket && (
                <Container className="ticketWrap">
                  <Row>
                    <Col>
                      <div className="pageTitle">
                        <h2>{ticket.title}</h2>
                        <div className="cta">
                          <Link className="button" to="/account/support/">
                            {i18n("backtoaccount")}
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={8}>
                      <div className="card alignedTable">
                        <div className="cardTitle">{i18n("messages")}</div>
                        <div className="cardContent infoList bg-secondary">
                          <div className="messagesWrap">
                            <div className="messages-inner">
                              <div className="messagesListWrap">
                                <div className="messagesListInner">
                                  {ticket.messages && (
                                    <>
                                      {ticket.messages.map((message, index) => [
                                        <div
                                          className={`message ${message.from}`}
                                          key={index}
                                        >
                                          <div className="messageContent">
                                            {message.message}
                                          </div>
                                          <div className="date">
                                            <Moment
                                              format="dddd MMMM Do, YYYY hh:mm A"
                                              date={message.date}
                                              tz="Europe/London"
                                            />
                                          </div>
                                        </div>,
                                      ])}
                                      <div
                                        style={{ float: "left", clear: "both" }}
                                        ref={(el) => {
                                          this.messagesEnd = el;
                                        }}
                                      ></div>
                                    </>
                                  )}
                                  {!ticket.messages && (
                                    <>
                                      <div className="nomessage">
                                        {i18n(
                                          "writeamessagetostartconversation"
                                        )}
                                      </div>
                                    </>
                                  )}
                                  {ticket.messages &&
                                    ticket.messages.length < 1 && (
                                      <>
                                        <div className="nomessage">
                                          {i18n(
                                            "writeamessagetostartconversation"
                                          )}
                                        </div>
                                      </>
                                    )}
                                </div>
                              </div>
                              <div className="messageFormWrap">
                                <TextareaAutosize
                                  name="message"
                                  cols="30"
                                  rows="50"
                                  placeholder={i18n("writeyourmessagehere")}
                                  className="form-control textarea"
                                  id="newMessageField"
                                  onBlur={(e) =>
                                    this.setState({
                                      ...this.state,
                                      newmessage: e.target.value,
                                    })
                                  }
                                />
                                <button
                                  className="button"
                                  onClick={this.addMessage}
                                >
                                  {i18n("send")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={4}>
                      <div className="card alignedTable">
                        <div className="cardTitle">{i18n("ticketdetails")}</div>
                        <div className="cardContent infoList withPadding bg-secondary">
                          <ul className="contentList">
                            <li>
                              <div className="label">Type</div>
                              <div className={`value`}>
                                {this.state.ticket.type}
                              </div>
                            </li>
                            {this.state.ticket.type === "lessons" && (
                              <li>
                                <div className="label">Lesson</div>
                                <div className={`value`}>
                                  <Link
                                    to={`/lesson/${this.state.ticket.element}`}
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      background: "transparent",
                                      display: "inline-block",
                                      border: 0,
                                    }}
                                  >
                                    {this.state.ticket.element}
                                  </Link>
                                </div>
                              </li>
                            )}
                            {this.state.ticket.type === "orders" && (
                              <li>
                                <div className="label">Orders</div>
                                <div className={`value`}>
                                  <Link
                                    to={`/order/${this.state.ticket.element}`}
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      background: "transparent",
                                      display: "inline-block",
                                      border: 0,
                                    }}
                                  >
                                    {this.state.ticket.element}
                                  </Link>
                                </div>
                              </li>
                            )}
                            <li>
                              <div className="label">{i18n("date")}</div>
                              <div className={`value`}>
                                <Moment
                                  format="DD/MM/YYYY hh:mm A"
                                  tz="Europe/London"
                                >
                                  {ticket.date}
                                </Moment>
                              </div>
                            </li>
                            <li>
                              <div className="label">{i18n("status")}</div>
                              {!this.state.inputToggled && (
                                <div
                                  className={`value ${
                                    this.state.ticket.status ===
                                      "In Progress" ||
                                    this.state.ticket.status ===
                                      "Awaiting Admin Reply"
                                      ? "text-info"
                                      : this.state.ticket.status ===
                                        "Awaiting User Reply"
                                      ? "text-danger"
                                      : "text-success"
                                  }`}
                                >
                                  {this.state.ticket.status}
                                  <span
                                    className="color-accent"
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "8px",
                                    }}
                                    onClick={this.showChange}
                                  >
                                    Change
                                  </span>
                                </div>
                              )}
                              {this.state.inputToggled && (
                                <div className={`value`}>
                                  <select
                                    onChange={(e) =>
                                      this.changeStatus(e.target.value)
                                    }
                                    className="form-control"
                                    defaultValue={this.state.ticket.status}
                                  >
                                    <option value="In Progress">
                                      In Progress
                                    </option>
                                    <option value="Resolved">Resolved</option>
                                    <option value="Awaiting Admin Reply">
                                      Awaiting Admin Reply
                                    </option>
                                  </select>
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </React.Fragment>
          )}
        {!this.state.ticket && (
          <Container className="fullScreenCenter vh70">
            <h1>{i18n("loading")}...</h1>
            <div className="spacer"></div>
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  info: state.info,
  support: state.support,
});

Ticket.propTypes = {
  auth: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  getUserTickets: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  changeTicketStatus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  setInfo,
  getUserTickets,
  sendMessage,
  changeTicketStatus,
})(withRouter(Ticket));
